import Layout from './Layout';
import logoKulture from '../assets/img/logo-kulture.png';
import Button from '../components/Button';
import useNavigate from '../utils/useNavigate';

function NotFound() {
	const navigate = useNavigate();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	return (
		<Layout>
			<div className="justify-content-center d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 mt-3 bg bg-vh-x1 bg_x2 h-100">
				<div className="containers container_main_view">
					<div className="row justify-content-center">
						<div className="col-12 col-md-8 text-center">
							<img className="logo_k4k_x1 img-fluid" src={logoKulture} alt="Kulture4Kids" />
							<h2 className="text-blue mt-5 mb-5">No se encontró lo que está buscando.</h2>
							<Button title="Ir al inicio" width="auto" action={() => goTo('/')} />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default NotFound;
