import React from 'react';
import rotate from '../assets/img/rotate.png';

function Rotate() {
	const ContentRotate = () => {
		return (
			<div className="rotate_section">
				<div className="rotate_content">
					<div className="rotate_icon">
						<img src={rotate} alt="" />
					</div>
					<h3>¡Gira tu pantalla!</h3>
				</div>
			</div>
		);
	};

	return <ContentRotate />;
}

export default Rotate;
