import Layout from './Layout';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useEffect, useState } from 'react';
import PointsGems from '../components/PointsGems';
import useNavigate from '../utils/useNavigate';
import logoKulture from '../assets/img/logo-kulture.png';
import blueDown from '../assets/img/icons/blue-down-arrow.png';
import DiamondSlider from '../components/DiamondSlider';
import LoaderSpinner from '../components/LoaderSpinner';
import ShopAvatarSlider from '../components/ShopAvatarSlider';

function Shop() {
	const navigate = useNavigate();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	const [puzzle, setPuzzle] = useState(true);
	const [avatar, setAvatar] = useState(false);
	const [loading, setLoading] = useState(true);

	const changeToAvatar = () => {
		setPuzzle(false);
		setAvatar(false);
		setLoading(true);

		setTimeout(() => {
			setPuzzle(false);
			setAvatar(true);
			setLoading(false);
		}, 1500);
	};

	const changeToPuzzle = () => {
		setPuzzle(false);
		setAvatar(false);
		setLoading(true);

		setTimeout(() => {
			setPuzzle(true);
			setAvatar(false);
			setLoading(false);
		}, 1500);
	};

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	return (
		<Layout>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 bg_x2 h-100 shop">
				<div className="containers container_main_view">
					<div className="row align-items-center mt-1 mb-2 ml-3 mr-3">
						<div className="col-4 col-md-4 col-lg-4 align-self-center">
							<div className="row align-items-center">
								<div className="mr-5">
									<img className="logo_k4k_x1 img-fluid" src={logoKulture} alt="Kulture4Kids" />
								</div>
							</div>
						</div>
						<div className="col-8 col-md-8 col-lg-8 align-self-center d-flex justify-content-end">
							<PointsGems />
						</div>

						<div className="col-12">
							<h2>Tienda</h2>
						</div>

						<div className="col-12">
							{loading === true ? (
								<LoaderSpinner />
							) : (
								<div className="row position-relative">
									{puzzle && (
										<div className="col-11 container-slider-puzzle">
											<h3>Rompecabezas</h3>

											<DiamondSlider />
										</div>
									)}

									{avatar && (
										<div className="col-11 container-slider-avatar">
											<h3>Avatares</h3>

											<ShopAvatarSlider />
										</div>
									)}
									<div className="col-12 mt-5 text-right justify-content-end d-flex">
										{puzzle && (
											<button type="button" onClick={changeToAvatar} className="blue-arrow-down">
												<span>Ver avatares</span>
												<img src={blueDown} alt="" />
											</button>
										)}

										{avatar && (
											<button type="button" onClick={changeToPuzzle} className="blue-arrow-down">
												<span>Ver rompecabezas</span>
												<img src={blueDown} alt="" />
											</button>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Shop;
