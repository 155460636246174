import React, { useEffect, useState } from 'react';

import bg from '../assets/img/background-login-student.png';
import logo from '../assets/img/logo-kulture.png';
import Button from '../components/Button';
import ModalResetPassword from '../components/modals/ModalResetPassword';
import useNavigate from '../utils/useNavigate';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../services/mutations';

function ResetPassword() {
	const [showResetPassword, setShowResetPassword] = useState(false);

	const initialValues = {
		email: '',
	};
	const [showModalEmailSent, setShowModalEmailSent] = useState(false);

	const [resetPasswordMutation, resultResetPassword] = useMutation(RESET_PASSWORD, {
		onCompleted(data) {
			console.log(data);
			setShowResetPassword(true);
		},
		onError(error) {
			console.log(error);
		},
	});

	const navigate = useNavigate();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	useEffect(() => {
		document.body.classList.add('bGreenLight');
	}, []);

	return (
		<React.Fragment>
			<main className="main_login">
				<div style={{ background: 'url(' + bg + ')' }} className="bg_img_login"></div>

				<div className="container">
					<div className="box_login_row">
						<div className="row box_login_container justify-content-center">
							<div className="col-md-8">
								<div className="content_login">
									<div className="logo text-center">
										<img src={logo} alt="" />
									</div>
									<h2 className="title_h1 text-center mt-3">¿Olvidó su contraseña?</h2>
									<div className="frm_login mt-5">
										<Formik
											initialValues={initialValues}
											validationSchema={Yup.object().shape({
												email: Yup.string()
													.email('Debe ser un correo electrónico válido')
													.required('El email es obligatorio'),
											})}
											onSubmit={(values, { setSubmitting }) => {
												setTimeout(() => {
													setSubmitting(false);
													console.log({
														email: values.email,
													});
													resetPasswordMutation({
														variables: {
															email: values.email,
														},
													});
												}, 500);
											}}
										>
											{({
												errors,
												handleBlur,
												handleChange,
												handleSubmit,
												isSubmitting,
												touched,
												values,
											}) => (
												<form
													onSubmit={handleSubmit}
													id="FrmNewPwd"
													name="FrmNewPwd"
													className="needs-validation"
													novalidate
												>
													<div className="form-row">
														<div className="col-md-12">
															<label htmlFor="nueva_clave">Ingrese su correo electrónico:</label>
															<div className="icon_input mt-2">
																<input
																	type="email"
																	className="form-control"
																	id="id_nueva_clave"
																	name="email"
																	placeholder="Correo electrónico"
																	required
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.email}
																/>
																{Boolean(touched.email && errors.email) && (
																	<div className="error">{touched.email && errors.email}</div>
																)}
															</div>
														</div>

														<div className="col-md-12 mt-5 mb-5 text-center">
															<Button
																disabled={isSubmitting}
																type="submit"
																title="Enviar"
																color="primary"
																width="auto"
																css="px-4"
															/>
														</div>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>

					<footer className="py-4 footer_cstm">
						<div className="container">
							<div className="row">
								<div className="col-md-12 text-center">
									<p>
										Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021 /{' '}
										<a>
											<strong>Políticas de privacidad</strong>
										</a>
									</p>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</main>

			<ModalResetPassword show={showResetPassword} setShow={setShowResetPassword} />
		</React.Fragment>
	);
}

export default ResetPassword;
