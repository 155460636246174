import React, { useEffect, useState } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import useNavigate from '../utils/useNavigate';
import DiamondPoints from '../components/DiamondPoints';

import avatar_1 from '../assets/img/avatar_1.png';
import ic_diamond from '../assets/img/icons/ic_diamond.png';
import Button from '../components/Button';
import ModalRedeemAvatar from './modals/ModalRedeemAvatar';
import { LIST_AVATARS, SHOP_PUZZLES } from '../services/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { BUY_ART, BUY_AVATAR } from '../services/mutations';
import useAuth from '../auth/useAuth';
import Messages from './modals/Messages';

const initialPopupMessage = {
	title: 'No cuenta con suficientes gemas para comprar.',
	message: '',
};

function ShopAvatarSlider() {
	const [info, setInfo] = useState([]);
	const [showRedeemPuzzle, setShowRedeemPuzzle] = useState(false);
	const [showFinishRedeemPuzzle, setShowFinishRedeemPuzzle] = useState(false);
	const [user, setUser] = useState([]);
	const [puzzles, setPuzzles] = useState([]);
	const [avatars, setAvatars] = useState([]);
	const [popup, setPopup] = useState(false);
	const [popupMessage, setPopupMessage] = useState(initialPopupMessage);
	const navigate = useNavigate();
	const getUser = useAuth();

	const [getAvatars, resultAvatars] = useLazyQuery(LIST_AVATARS, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				console.log(result);
				if (result.listAvatars) {
					console.log('RESULTADO: AVATARS: ', result.listAvatars);
					setAvatars(result.listAvatars);
				}
			}
		},
	});

	const goTo = (url) => {
		navigate.goTo(url);
	};

	const [puzzleSlider, setPuzzleSlider] = useState(null);

	const do_prev_puzzle = () => {
		puzzleSlider.slidePrev();
	};

	const do_next_puzzle = () => {
		puzzleSlider.slideNext();
	};

	useEffect(() => {
		if (avatars.length > 0) {
			Swiper.use([Navigation, Pagination]);

			let tempPuzzle = new Swiper('.swiper-puzzle', {
				loop: false,
				slidesPerView: 4,
				spaceBetween: 15,
				centeredSlides: false,
				breakpoints: {
					640: {
						slidesPerView: 2,
						spaceBetween: 15,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 15,
					},
					1440: {
						slidesPerView: 4,
						spaceBetween: 15,
					},
				},
			});
			setPuzzleSlider(tempPuzzle);
		}
	}, [avatars]);

	const [newAvatar, resultBuyAvatar] = useMutation(BUY_AVATAR, {
		onCompleted(data) {
			if (data.buyAvatar) {
				console.log('RESULTADO: BUY_AVATAR: ', data.buyAvatar);
				setShowRedeemPuzzle(false);
				setShowFinishRedeemPuzzle(true);
				getAllAvatars();
			} else {
				setShowRedeemPuzzle(false);
				setPopup(true);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const SaveBuy = () => {
		console.log('newAvatar: ', {
			id: parseInt(info.id),
			howMuch: parseInt(info.gems),
		});
		newAvatar({
			variables: {
				id: parseInt(info.id),
				howMuch: parseInt(info.gems),
			},
		});
	};

	const getAllAvatars = () => {
		getAvatars();
	};

	useEffect(() => {
		setUser(getUser.getUser());
		getAllAvatars();
	}, []);

	useEffect(() => {
		if (showFinishRedeemPuzzle == false) {
			setInfo([]);
		}
	}, [showFinishRedeemPuzzle]);

	return (
		<React.Fragment>
			{avatars && (
				<div className="container-diamond-slider">
					<div className="row justify-content-center">
						<div className="col-11 col-md-10 overflow-hidden">
							<div className="swiper swiper-puzzle slider swiper-container">
								<div className="swiper-wrapper">
									{avatars.map((item) => {
										return (
											<div className="swiper-slide">
												<div className="content-shop">
													<div className="shop-left">
														<img src={`${item.image}`} className="img-fluid" />
													</div>
													<div className="shop-right">
														<h5>{item.title}</h5>
														<DiamondPoints points={item.gems} />
													</div>
												</div>

												<Button
													action={() => {
														setInfo(item);
														setShowRedeemPuzzle(true);
													}}
													width="auto"
													title="Comprar"
													color="purple"
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>

					<a
						onClick={do_prev_puzzle}
						className="carousel-control-prev"
						href="#myCarousel"
						data-slide="prev"
					>
						<i className="fa fa-chevron-left"></i>
					</a>

					<a
						onClick={do_next_puzzle}
						className="carousel-control-next"
						href="#myCarousel"
						data-slide="next"
					>
						<i className="fa fa-chevron-right"></i>
					</a>
				</div>
			)}

			{popupMessage && (
				<Messages
					show={popup}
					setShow={setPopup}
					title={popupMessage.title}
					message={popupMessage.message}
				/>
			)}

			{showRedeemPuzzle == true && (
				<>
					<div
						className={'modal fade ' + (showRedeemPuzzle ? 'show modal-block' : 'modal-none')}
						id="modalConfirmPurchase"
						tabIndex="-1"
						aria-labelledby="modalConfirmPurchaseLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content radious-modal">
								<div className="modal-header notBrHeader mb-top15">
									<h5 className="titleModalHeader-2">¿Canjear Avatar?</h5>
								</div>
								<div className="modal-body">
									<div className="d-flex justify-content-center mb-2">
										<div className="row justify-content-center">
											<div className="col-5 text-center">
												<img src={`${info.image}`} className="img-fluid" />
											</div>
											<div className="col-12 p-3">
												<h6 className="card-subtitle my-1 avatar_name text-truncate text-center">
													{info.title}
												</h6>
												<div className="container_bagde_points_x2">
													<div className="row">
														<img src={ic_diamond} className="diamond_redeem_img" />
														<div className="points_redeem_badge">{info.gems}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer buttonFooterWLogin mt-0 mb-3 mt-md-3">
									<div className="row">
										<div className="col-6 col-md-6">
											<Button
												title="Cancelar"
												color="red"
												action={() => setShowRedeemPuzzle(false)}
											/>
										</div>
										<div className="col-6 col-md-6">
											<Button
												action={() => {
													SaveBuy();
												}}
												title="Confirmar canje"
												color="purple"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'modal-backdrop fade ' + (showRedeemPuzzle ? 'show' : '')}></div>
				</>
			)}

			{showFinishRedeemPuzzle == true && (
				<>
					<div
						className={'modal fade ' + (showFinishRedeemPuzzle ? 'show modal-block' : 'modal-none')}
						id="modalConfirmPurchase"
						tabIndex="-1"
						aria-labelledby="modalConfirmPurchaseLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content radious-modal">
								<div className="modal-header notBrHeader mb-top15">
									<h5 className="titleModalHeader-2">Avatar canjeado</h5>
								</div>
								<div className="modal-body">
									<div className="d-flex justify-content-center mb-2">
										<div className="row justify-content-center">
											<div className="col-5 text-center">
												<img src={info.image} className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer buttonFooterWLogin mt-0 mb-3 mt-md-3">
									<div className="row">
										<div className="col-12 col-md-12">
											<Button
												title="Cerrar"
												color="red"
												action={() => setShowFinishRedeemPuzzle(false)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'modal-backdrop fade ' + (showFinishRedeemPuzzle ? 'show' : '')}></div>
				</>
			)}
		</React.Fragment>
	);
}

export default ShopAvatarSlider;
