import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../auth/useAuth';
import Button from '../Button';

function ModalLogout({ show, setShow }) {
	const history = useHistory();
	const location = useLocation();
	const previusObjectURL = location.state?.from;
	const auth = useAuth();

	const logout = () => {
		auth.logout();
		history.push(previusObjectURL || '/login');
	};

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalConfirmLogOut"
				tabIndex="-1"
				aria-labelledby="modalConfirmLogOutLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content radious-modal px-4">
						<div className="modal-header notBrHeader">
							<br />
							<h5 className="titleModalHeader-2">¿Seguro que quieres cerrar sesión?</h5>
						</div>

						<div className="modal-footer mb-4">
							<div className="row">
								<div className="col-6">
									<Button title="Cancelar" color="red" action={() => setShow(false)} />
								</div>
								<div className="col-6">
									<Button title="Cerrar Sesión" color="purple" action={logout} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalLogout;
