import React, { useEffect, useState } from 'react';

import close_img from '../../assets/img/icons/i-close.png';

function ModalInfoPuzzle({ show, setShow, info }) {
	return (
		<>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalAboutTheWork"
				tabIndex="-1"
				aria-labelledby="modalAboutTheWorkLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content radious-modal">
						<a
							type="button"
							className=""
							data-dismiss="modal"
							aria-hidden="true"
							onClick={() => setShow(false)}
						>
							<img
								className="btn-close closeBtnModal"
								data-bs-dismiss="modal"
								aria-label="Close"
								src={close_img}
							/>
						</a>
						<div className="modal-header notBrHeader p-0">
							{/*}
							<div
								className="bg_work_header"
								style={{ background: 'url(' + info.artist_image + ')', backgroundSize: 'cover' }}
							></div>
							{*/}
							<img src={info.artist_image} alt="" className="img-fluid" />
						</div>
						<div className="modal-body">
							<div className="container_about_the_work">
								<h1 id="title_work_name" className="title_work_name">
									{info.name}
								</h1>
								{/*}
								<h3 id="title_author_name" className="title_author_name">
									{info.artists_id}
								</h3>
	{*/}
								<div className="container_description_about_work">{info.description}</div>
							</div>
						</div>
						<div className="modal-footer buttonFooterWLogin mb-3"></div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</>
	);
}

export default ModalInfoPuzzle;
