import React, { useEffect, useState } from 'react';
import Rotate from './Rotate';
import Sidebar from './Sidebar';

function Layout({ children }) {
	const [vw, setVw] = useState(
		Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
	);
	const [shSidebar, setshSidebar] = useState(true);

	const [toggleSidebarPosition, settoggleSidebarPosition] = useState(0);

	useEffect(() => {
		function handleResize() {
			setVw(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
			sidebarMenu();
		}
		window.addEventListener('resize', handleResize);

		sidebarMenu();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const sidebarMenu = () => {
		shSidebar == true
			? settoggleSidebarPosition(document.getElementById('sidebarMenu').offsetWidth)
			: settoggleSidebarPosition(0);
	};

	useEffect(() => {
		//console.log('localStorage => ' + localStorage.getItem("k4k_toggleSidebar"));
		// localStorage.setItem("k4k_toggleSidebar", shSidebar);
		sidebarMenu();
	}, [shSidebar]);

	const changeClass = () => {
		shSidebar === true ? setshSidebar(false) : setshSidebar(true);
	};

	if ((vw >= 640 && vw < 768) || vw >= 1024) {
		return (
			<React.Fragment>
				<div id="k4k_site" className="sidebar-opened">
					<div className="container-fluid">
						<div className="row position-relative">
							<div
								onClick={changeClass}
								className="toggleLayout"
								style={{ left: toggleSidebarPosition }}
							>
								<i class={'fas ' + (shSidebar == true ? 'fa-caret-left' : 'fa-caret-right')}></i>
							</div>

							<Sidebar sidebar={shSidebar} />

							<main
								role="main"
								className={
									'pr-0 pl-lg-0 pl-0 ' +
									(shSidebar == true
										? 'col-10 col-md-9 col-lg-11 col-xl-10'
										: 'col-12 col-md-12 col-lg-12 col-xl-12')
								}
							>
								<div className="border_content h-100">{children}</div>
							</main>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	} else return <Rotate />;
}

export default Layout;
