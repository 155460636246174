import ic_diamond from '../../assets/img/icons/ic_diamond.png';
import Button from '../Button';

export default function Messages({ title, message, setShow, show }) {
	return (
		<>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalConfirmPurchase"
				tabIndex="-1"
				aria-labelledby="modalConfirmPurchaseLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content radious-modal">
						{title && (
							<div className="modal-header notBrHeader mb-top15">
								<h5 className="titleModalHeader-2">{title}</h5>
							</div>
						)}
						{message && (
							<div className="modal-body">
								<div className="d-flex justify-content-center mb-2">
									<div className="row justify-content-center">
										<div className="col-12 p-0">
											<h6 className="card-subtitle avatar_name text-truncate text-center">
												{message}
											</h6>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="modal-footer buttonFooterWLogin mt-0 pt-0 mb-3 mt-md-3">
							<div className="row justify-content-center">
								<div className="col-12 col-md-6">
									<Button title="Cerrar" color="red" action={() => setShow(false)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</>
	);
}
