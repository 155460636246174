import './sass/App.scss';
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthProvider from './auth/AuthProvider';
import NotFound from './views/NotFound';
import PublicRoute from './routers/PublicRoute';

import Home from './views/Home';
import Profile from './views/Profile';
import CreateProfile from './views/students/CreateProfile';
import Points from './views/Points';
import Puzzle from './views/Puzzle';
import Levels from './views/Levels';
import Shop from './views/Shop';

import Login from './views/Login';
import CreateAccount from './views/CreateAccount';
import ActiveUser from './views/ActiveUser';
import PrivateRoute from './routers/PrivateRoute';
import PuzzleGame from './views/PuzzleGame';
import ResetPassword from './views/ResetPassword';
import NewPassword from './views/NewPassword';
import { GlobalDebug } from './utils/general';

function App() {
	useEffect(() => {
		process.env.REACT_APP_ENV === 'production' && GlobalDebug(false);
	}, []);

	return (
		<div>
			<AuthProvider>
				<Switch>
					<PublicRoute exact path="/login" component={Login} />
					<PublicRoute exact path="/activeuser" component={ActiveUser} />
					<PublicRoute exact path="/createaccount" component={CreateAccount} />
					<PublicRoute exact path="/resetpassword" component={ResetPassword} />
					<PublicRoute exact path="/newpassword" component={NewPassword} />

					<PrivateRoute exact path="/profile" component={Profile} />
					<PrivateRoute exact path="/profile/create" component={CreateProfile} />

					<PrivateRoute exact path="/points" component={Points} />

					<PrivateRoute exact path="/puzzle" component={Puzzle} />
					<PrivateRoute exact path="/puzzlegame/:id/:pieces" component={PuzzleGame} />

					<PrivateRoute exact path="/levels/:id" component={Levels} />
					<PrivateRoute exact path="/shop" component={Shop} />
					<PrivateRoute exact path="/" component={Home} />

					<Route path="/404" component={NotFound} />
					<Route path="*">
						<Redirect to="/404" />
					</Route>
				</Switch>
			</AuthProvider>
		</div>
	);
}

export default App;
