import React, { useEffect, useState } from 'react';

import bg from '../assets/img/background-login-student.png';
import logo from '../assets/img/logo-kulture.png';
import Button from '../components/Button';
import ModalResetPassword from '../components/modals/ModalResetPassword';
import useNavigate from '../utils/useNavigate';

import { useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { VALIDATE_RESET_PASSWORD } from '../services/queries';
import { NEW_PASSWORD } from '../services/mutations';

import * as Yup from 'yup';
import { Formik } from 'formik';

function NewPassword() {
	const initialValues = {
		password: '',
		password_confirm: '',
	};

	const [showResetPassword, setShowResetPassword] = useState(false);
	const [verify, setVerify] = useState(false);

	const search = useLocation().search;
	const code = new URLSearchParams(search).get('code');
	const id = new URLSearchParams(search).get('id');

	const navigate = useNavigate();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	const [validate, resultValidate] = useLazyQuery(VALIDATE_RESET_PASSWORD, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				console.log(result);
				if (result.validateResetPassword) {
					console.log('RESULTADO: Validate', result.validateResetPassword);
					setVerify(result.validateResetPassword);
				}
			}
		},
	});

	const [passwordMutation, resultPassword] = useMutation(NEW_PASSWORD, {
		onCompleted(data) {
			console.log(data);
			if (data.newResetPassword) {
				if (data.newResetPassword.result) {
					setShowResetPassword(true);
				}
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	useEffect(() => {
		document.body.classList.add('bGreenLight');

		console.log({
			code,
			id: parseInt(id),
		});

		validate({
			variables: {
				code,
				id: parseInt(id),
			},
		});
	}, []);

	const PasswordForm = () => {
		return (
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					password: Yup.string()
						.required('La contraseña es requerida.')
						.min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
						.matches(
							/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
							'Debe de contener mayúscula, número y al menos un caracter especial.',
						),
					password_confirm: Yup.string()
						.required('Confirmar la contraseña es requerido.')
						.min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
						.oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.')
						.matches(
							/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
							'Debe de contener al menos una mayúscula, un número y un caracter especial.',
						),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(false);
						console.log({
							id,
							code,
							password: values.password,
						});
						passwordMutation({
							variables: {
								id,
								code,
								password: values.password,
							},
						});
					}, 500);
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form
						onSubmit={handleSubmit}
						id="FrmNewPwd"
						name="FrmNewPwd"
						className="needs-validation"
						noValidate
					>
						<div className="form-row">
							<div className="col-md-6">
								<label htmlFor="nueva_clave">Tu nueva clave secreta</label>
								<div className="icon_input mt-2">
									<input
										type="password"
										className="form-control password i-pass id_nueva_clave"
										id="id_nueva_clave"
										name="password"
										placeholder="••••••••"
										required
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
									/>
									{Boolean(touched.password && errors.password) && (
										<div className="error">{touched.password && errors.password}</div>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<label htmlFor="confirmar_clave">Confirma tu clave secreta</label>
								<div className="icon_input mt-2">
									<input
										type="password"
										className="form-control password i-pass id_confirmar_clave"
										id="id_confirmar_clave"
										name="password_confirm"
										placeholder="••••••••"
										required
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password_confirm}
									/>

									{Boolean(touched.password_confirm && errors.password_confirm) && (
										<div className="error">
											{touched.password_confirm && errors.password_confirm}
										</div>
									)}
								</div>
							</div>
							<div className="col-md-12 mt-5 mb-5 text-center">
								<Button
									disabled={isSubmitting}
									title="Finalizar"
									color="primary"
									width="auto"
									css="px-4"
									type="submit"
								/>
							</div>
						</div>
					</form>
				)}
			</Formik>
		);
	};

	return (
		<React.Fragment>
			<main className="main_login">
				<div style={{ background: 'url(' + bg + ')' }} className="bg_img_login"></div>

				<div className="container">
					<div className="box_login_row">
						<div className="row box_login_container justify-content-center">
							<div className="col-md-10">
								<div className="content_login">
									<div className="logo text-center">
										<img src={logo} alt="" />
									</div>
									<h2 className="title_h1 text-center mt-3">Crea tu nueva clave secreta</h2>
									<div className="frm_login mt-5">
										{!verify ? (
											<>
												<h4 className="text-center">No hay información válida para proceder.</h4>
												<div className="mb-5"></div>
											</>
										) : (
											<PasswordForm />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<footer className="py-4 footer_cstm">
						<div className="container">
							<div className="row">
								<div className="col-md-12 text-center">
									<p>
										Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021 /{' '}
										<a>
											<strong>Políticas de privacidad</strong>
										</a>
									</p>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</main>

			<ModalResetPassword show={showResetPassword} setShow={setShowResetPassword} />
		</React.Fragment>
	);
}

export default NewPassword;
