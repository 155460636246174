import ic_star from '../assets/img/icons/ic_star.png';

function StarPoints(props) {
	return (
		<div className="container_bagde_points p-0">
			<img src={ic_star} className="img-fluid star_points" />
			<div className="points_winner_badge">{props.points}</div>
		</div>
	);
}

export default StarPoints;
