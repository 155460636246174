import { useEffect, useState } from 'react';
import PointsGems from './PointsGems';

import marilyn from '../assets/img/puzzle/marilyn.png';
import van from '../assets/img/puzzle/van.png';
import ic_pending_puzzle from '../assets/img/icons/ic_pending_puzzle.png';
import Button from '../components/Button';
import { PENDING_PUZZLES } from '../services/queries';
import { useLazyQuery } from '@apollo/client';
import useNavigate from '../utils/useNavigate';

function PuzzleBox() {
	const navigate = useNavigate();
	const [empty, setEmpty] = useState(false);
	const [pending, setPending] = useState(false);

	const [puzzles, resultPuzzles] = useLazyQuery(PENDING_PUZZLES, {
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (resultPuzzles.data) {
			if (resultPuzzles) {
				console.log(resultPuzzles.data);
				if (resultPuzzles.data) {
					console.log('RESULTADO: Pending: ', resultPuzzles.data.pending_puzzles);
					setPending(resultPuzzles.data.pending_puzzles);
					setEmpty(false);
				} else setEmpty(true);
			} else setEmpty(true);
		}
	}, [resultPuzzles]);

	const goToPuzzle = (index) => {
		goTo('/puzzlegame/' + index + '/16');
	};

	const goTo = (url) => {
		navigate.goTo(url);
	};

	useEffect(() => {
		puzzles({
			variables: {
				limit: 3,
			},
		});
	}, []);

	if (!pending) return false;

	return (
		<div className="home_after_tour">
			<h3 className="title-head-section">Mis puntos.</h3>
			<PointsGems />

			{empty == false ? (
				<div className="home_puzzle">
					<div className="row">
						<div className="col-12">
							<h4>Rompecabezas pendientes:</h4>
						</div>
					</div>

					{pending &&
						pending.map((item) => (
							<div className="puzzle_item">
								<div className="row">
									<div className="col-5 pr-0 pr-lg-auto col-md-3 align-self-center">
										<a onClick={() => goToPuzzle(item.id)}>
											<img src={item.thumbnail} alt="" className="img-fluid" />
										</a>
									</div>
									<div className="col-7 col-md-9 align-self-center">
										<h5>
											<a onClick={() => goToPuzzle(item.id)}>{item.name}</a>
										</h5>
										<p>
											<strong>Pintor: </strong>
											{`${item.artist_first_name} ${item.artist_last_name}`}
										</p>
									</div>
								</div>
							</div>
						))}
				</div>
			) : (
				<div className="home_puzzle home_puzzle_empty">
					<div className="row">
						<div className="col-12">
							<img width="70" src={ic_pending_puzzle} className="icon_pending" alt="" />
							<h4>
								No tienes rompecabezas <br /> pendientes.
							</h4>
							<Button title="Mejora tus tiempos" color="purple" width="auto" />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default PuzzleBox;
