import Layout from './Layout';

import logoKulture from '../assets/img/logo-kulture.png';

import dif_1 from '../assets/img/ic_difficulty_16.png';
import dif_2 from '../assets/img/ic_difficulty_36.png';
import dif_3 from '../assets/img/ic_difficulty_64.png';
import dif_4 from '../assets/img/ic_difficulty_100.png';
import useNavigate from '../utils/useNavigate';
import Button from '../components/Button';

import { useParams } from 'react-router';

function Levels() {
	const navigate = useNavigate();
	const { id } = useParams();

	const goToPuzzle = (count) => {
		navigate.goTo('/puzzlegame/' + id + '/' + count);
	};

	return (
		<Layout>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 mt-3 bg bg-vh-x1 bg_x2 h-100">
				<div className="containers container_main_view">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
							<div className="mt-3 mb-3">
								<img className="" src={logoKulture} alt="Kulture4Kids" />
							</div>
							<div className="mt-3 mb-3">
								<h3 className="title_head_level_puzzple">Elige la dificultad del rompecabezas</h3>
							</div>
						</div>
					</div>
					<div className="row mt-3 mb-3"></div>

					<form id="FrmDificultad" name="FrmDificultad">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
								<div className="row">
									<input type="hidden" id="id_dificultad" name="dificultad" />
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2">
										<div className="card card_level_puzzle tex-center">
											<div className="card-body">
												<img className="" src={dif_1} />
												<h5 className="card-title title_pieces_puzzle text-blue text-uppercase">
													Piezas
												</h5>
												<p className="card-text text_level_puzzle text-cyan-sky-1">Muy fácil</p>
												<div className="mt-3 mb-3"></div>
												<Button
													size="small"
													title="Seleccionar"
													color="blue"
													action={() => goToPuzzle(16)}
												/>
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2">
										<div className="card card_level_puzzle tex-center">
											<div className="card-body">
												<img className="" src={dif_2} />
												<h5 className="card-title title_pieces_puzzle text-yellow-1 text-uppercase">
													Piezas
												</h5>
												<p className="card-text text_level_puzzle text-cyan-sky-1">Fácil</p>
												<div className="mt-3 mb-3"></div>
												<Button
													size="small"
													title="Seleccionar"
													color="yellow"
													action={() => goToPuzzle(36)}
												/>
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2">
										<div className="card card_level_puzzle tex-center">
											<div className="card-body">
												<img className="" src={dif_3} />
												<h5 className="card-title title_pieces_puzzle text-purple-1 text-uppercase">
													Piezas
												</h5>
												<p className="card-text text_level_puzzle text-cyan-sky-1">Difícil</p>
												<div className="mt-3 mb-3"></div>
												<Button
													size="small"
													title="Seleccionar"
													color="purple"
													action={() => goToPuzzle(64)}
												/>
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 mb-2">
										<div className="card card_level_puzzle tex-center">
											<div className="card-body">
												<img className="" src={dif_4} />
												<h5 className="card-title title_pieces_puzzle text-danger-1 text-uppercase">
													Piezas
												</h5>
												<p className="card-text text_level_puzzle text-cyan-sky-1">Muy difícil</p>
												<div className="mt-3 mb-3"></div>
												<Button
													size="small"
													title="Seleccionar"
													color="red"
													action={() => goToPuzzle(100)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Layout>
	);
}

export default Levels;
