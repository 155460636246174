import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';

import backgroundLogin from '../assets/img/background-login-student.png';
import logoKulture from '../assets/img/logo-kulture.png';
import ModalEmailSent from '../components/modals/ModalEmailSent';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { SIGNUP } from '../services/mutations';
import { useMutation } from '@apollo/client';

function CreateAccount() {
	const initialValues = {
		email: '',
		mentor: '',
		password: '',
		password_confirm: '',
	};
	const [showModalEmailSent, setShowModalEmailSent] = useState(false);

	const [signupMutation, resultSignup] = useMutation(SIGNUP, {
		onCompleted(data) {
			console.log(data);
			setShowModalEmailSent(true);
		},
		onError(error) {
			console.log(error);
		},
	});

	useEffect(() => {
		document.body.classList.add('bGreenLight');
	}, []);

	return (
		<React.Fragment>
			<main className="main_login">
				<img className="bg_img_login" src={backgroundLogin} alt="" />

				<div className="container">
					<div className="box_login_row">
						<div className="box_login_container row justify-content-center">
							<div className="col-md-10">
								<div className="content_login">
									<div className="logo text-center">
										<img src={logoKulture} alt="" />
									</div>
									<h2 className="title_h1 text-center mt-3">¡Crear nueva cuenta!</h2>
									<div className="frm_login mt-5">
										<Formik
											initialValues={initialValues}
											validationSchema={Yup.object().shape({
												email: Yup.string()
													.email('Debe ser un correo electrónico válido')
													.required('El email es obligatorio'),
												mentor: Yup.string()
													.email('Debe ser un correo electrónico válido')
													.required('El tutor es obligatoria'),
												password: Yup.string()
													.required('La contraseña es requerida.')
													.min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
													.matches(
														/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
														'Debe de contener mayúscula, número y al menos un caracter especial.',
													),
												password_confirm: Yup.string()
													.required('Confirmar la contraseña es requerido.')
													.min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
													.oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.')
													.matches(
														/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
														'Debe de contener al menos una mayúscula, un número y un caracter especial.',
													),
											})}
											onSubmit={(values, { setSubmitting }) => {
												setTimeout(() => {
													setSubmitting(false);
													console.log({
														email: values.email,
														mentor: values.mentor,
														password: values.password,
														role: 4,
													});
													signupMutation({
														variables: {
															email: values.email,
															mentor: values.mentor,
															password: values.password,
															role: 4,
														},
													});
												}, 500);
											}}
										>
											{({
												errors,
												handleBlur,
												handleChange,
												handleSubmit,
												isSubmitting,
												touched,
												values,
											}) => (
												<form
													onSubmit={handleSubmit}
													name="FrmSignUp"
													id="FrmSignUp"
													className="needs-validation"
													novalidate
												>
													<div className="form-row">
														<div className="col-md-6 mt-4">
															<label htmlFor="correo_usuario">Tu correo electrónico</label>
															<div className="icon_input">
																<input
																	type="email"
																	className="mt-2 form-control"
																	id="id_correo_usuario"
																	name="email"
																	placeholder="Escribir correo..."
																	required
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.email}
																/>
																{Boolean(touched.email && errors.email) && (
																	<div className="error">{touched.email && errors.email}</div>
																)}
															</div>
														</div>
														<div className="col-md-6 mt-4">
															<label htmlFor="correo_tutor">
																Correo de tu padre, madre o tutor
															</label>
															<div className="icon_input">
																<input
																	type="email"
																	className="mt-2 form-control"
																	id="id_correo_tutor"
																	name="mentor"
																	placeholder="Escribir correo..."
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.mentor}
																/>
																{Boolean(touched.mentor && errors.mentor) && (
																	<div className="error">{touched.mentor && errors.mentor}</div>
																)}
															</div>
														</div>
													</div>
													<div className="form-row">
														<div className="col-md-6 mt-4">
															<label htmlFor="nueva_clave">Crear clave secreta</label>
															<div className="icon_input">
																<input
																	type="password"
																	className="mt-2 form-control password i-pass"
																	id="id_nueva_clave"
																	name="password"
																	placeholder="••••••••"
																	required
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.password}
																/>
																{Boolean(touched.password && errors.password) && (
																	<div className="error">{touched.password && errors.password}</div>
																)}
															</div>
														</div>
														<div className="col-md-6 mt-4">
															<label htmlFor="confirmar_clave">Confirma tu clave secreta</label>
															<div className="icon_input">
																<input
																	type="password"
																	className="mt-2 form-control password i-pass"
																	id="id_confirmar_clave"
																	name="password_confirm"
																	placeholder="••••••••"
																	required
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.password_confirm}
																/>
																{Boolean(touched.password_confirm && errors.password_confirm) && (
																	<div className="error">
																		{touched.password_confirm && errors.password_confirm}
																	</div>
																)}
															</div>
														</div>
														<div className="col-md-12 mt-4 mb-5 text-center">
															<Button
																disabled={isSubmitting}
																title="Crear cuenta"
																color="primary"
																width="auto"
																type="submit"
															/>
														</div>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="modal fade"
						id="modalSuccessEmail"
						tabIndex="-1"
						aria-labelledby="modalSuccessEmailLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog">
							<div className="modal-content radious-modal">
								<div className="modal-header notBrHeader"></div>
								<div className="modal-body">
									<p className="textMessage text-blue">
										Te hemos enviado un correo electrónico para confirmar tu cuenta e iniciar
										sesión.
									</p>
								</div>
								<div className="modal-footer buttonFooterWLogin">
									<a type="button" className="btn btn-primary buttonWLogin text-uppercase">
										Iniciar Sesión
									</a>
								</div>
							</div>
						</div>
					</div>

					<footer className="py-4 footer_cstm">
						<div className="container">
							<div className="row">
								<div className="col-md-12 text-center">
									<p>
										Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021 /{' '}
										<strong>Políticas de privacidad</strong>
									</p>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</main>

			<ModalEmailSent show={showModalEmailSent} setShow={setShowModalEmailSent} />
		</React.Fragment>
	);
}

export default CreateAccount;
