import Layout from './Layout';

import logoKulture from '../assets/img/logo-kulture.png';
import ic_play_tutorial from '../assets/img/icons/ic_play_tutorial.png';
import institute from '../assets/img/logo-institute-default.png';
import { useEffect, useState } from 'react';
import AvatarMaker from '../components/avatar/AvatarMaker';
import PointsGems from '../components/PointsGems';
import AvatarSlider from '../components/AvatarSlider';
import Button from '../components/Button';
import LoaderSpinner from '../components/LoaderSpinner';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_INFO_USER } from '../services/mutations';
import { GET_CUSTOM_AVATAR, GET_MY_SCHOOL_INFO, ME } from '../services/queries';
import ic_diamond from '../assets/img/icons/ic_diamond.png';

import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from '../auth/useAuth';

function Profile() {
	const [gender, setGender] = useState('male');
	const [mounted, setMounted] = useState(false);
	const [info, setInfo] = useState([]);
	//const [me, setMe] = useState([]);
	const [loading, setLoading] = useState(false);
	const [buyed, setBuyed] = useState(false);
	const [reinitMaker, setReinitMaker] = useState(null);
	const [custom, setCustom] = useState(null);
	const [initialValues, setInitialValues] = useState([]);
	const [schoolInfo, setSchoolInfo] = useState([]);

	const user = useAuth();
	const me = user.getUser();

	const [getInfo, resultGetInfo] = useLazyQuery(GET_MY_SCHOOL_INFO, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result && result.mySchoolInfo) {
				console.log('RESULTADO: GET_MY_SCHOOL_INFO', result.mySchoolInfo);
				setSchoolInfo(result.mySchoolInfo);
			}
		},
	});

	const [getCustom, resultCustom] = useLazyQuery(GET_CUSTOM_AVATAR, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result && result.userCustomAvatars) {
				console.log('RESULTADO: GET_CUSTOM_AVATAR: ', result.userCustomAvatars);
				setCustom(result.userCustomAvatars);
			}
		},
	});

	const [saveInfoMutation, resultSaveInfo] = useMutation(UPDATE_INFO_USER, {
		onCompleted(data) {
			//console.log(data);
			setInfo(data.updateUser);
			goToStep(2);
		},
		onError(error) {
			console.log(error);
		},
	});

	useEffect(() => {
		setMounted(true);
		//getMe();

		localStorage.setItem('k4k_waitfor_custom', true);
		console.log('TEST - - - - - - - - - - - - ', localStorage.getItem('k4k_waitfor_custom'));
		getCustom();
	}, []);

	useEffect(() => {
		if (custom && custom.id) {
			setBuyed(true);
		}
	}, [custom]);

	useEffect(() => {
		if (me) {
			setInitialValues({
				first_name: me.first_name,
				last_name: me.last_name,
				age: me.age,
				mentor: me.mentor,
				email: me.email,
			});

			setGender(me.gender_id == 1 ? 'male' : me.gender_id == 2 ? 'female' : null);
		}

		if (me && me.roleId === 3) {
			console.log('MEEEEEEE - - - - - - - - - -- - - - - -', me);
			getInfo({
				variables: {
					userId: me.id,
				},
			});
		}
	}, [me]);

	const extractElements = () => {
		window.jQuery(document).ready(function ($) {
			'use strict';

			console.log($('#svga-elements').html());
		});
	};

	const doReMaker = (param) => {
		setReinitMaker(param);
	};

	const isBuyed = (doBuyed) => {
		setBuyed(doBuyed);
	};

	useEffect(() => {
		console.log('buyed ::: ', buyed);
	}, [buyed]);

	if (!me) return false;

	return (
		<Layout>
			<div className="row align-items-center mt-1 mb-2 ml-3 mr-3">
				<div className="col-4 col-md-4 pr-0 pr-lg-auto">
					<div className="row">
						<div className="col-5 col-md-6 align-self-center pl-0 pl-lg-auto">
							<img className="logo_k4k_x1 img-fluid" src={logoKulture} alt="Kulture4Kids" />
						</div>
						<div className="col-7 col-md-6 align-self-center pr-0 pr-lg-auto">
							<h3 className="title_head_my_profile">Mi perfil</h3>
						</div>
					</div>
				</div>

				<div className="col-8 col-md-8">
					<div className="row">
						<div className="col-5 col-md-4 text-right align-self-center"></div>
						<div className="col-7 col-md-8 pr-0 pl-0 justify-content-end d-flex align-self-center">
							<PointsGems />
						</div>
					</div>
				</div>
			</div>

			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center mt-4 mb-4 bg_secondary">
				<div className="container_main_view">
					<div className="row">
						<div className="col-12 col-md-4 col-lg-4 position-relative">
							<Formik
								enableReinitialize
								initialValues={initialValues}
								validationSchema={Yup.object().shape({
									first_name: Yup.string().nullable(true).required('El nombre es obligatorio'),
									last_name: Yup.string().nullable(true).required('El apellido es obligatorio'),
									age: Yup.number().nullable(true).required('La edad es obligatoria'),
									email: Yup.string()
										.email('Debe ser un correo electrónico válido')
										.required('El correo electrónico es obligatorio'),
									mentor: Yup.string()
										.email('Debe ser un correo electrónico válido')
										.required('El correo de su tutor es obligatorio'),
								})}
								onSubmit={(values, { setSubmitting }) => {
									extractElements();
									/* setTimeout(() => {
										setSubmitting(false);
										console.log({
											first_name: values.first_name,
											age: values.age,
											email: values.email,
											mentor: values.mentor,
										});
										saveInfoMutation({
											variables: {
												first_name: values.first_name,
												age: values.age,
												email: values.email,
												mentor: values.mentor,
											},
										});
									}, 500); */
								}}
							>
								{({
									errors,
									handleBlur,
									handleChange,
									handleSubmit,
									isSubmitting,
									touched,
									values,
								}) => (
									<form
										onSubmit={handleSubmit}
										id="FrmProfile"
										name="FrmProfile"
										autoComplete="off"
										noValidate
									>
										<div className="form-row">
											<div className="col-12 col-md-12 col-lg-12 text-center">
												<label htmlFor="nombre">Mi nombre</label>
												<div className="icon_input">
													<input
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														className="form-control"
														id="first_name"
														name="first_name"
														placeholder="Nombre"
														required
														value={values.first_name}
													/>
													{Boolean(touched.first_name && errors.first_name) && (
														<div className="error">{touched.first_name && errors.first_name}</div>
													)}
												</div>
											</div>
										</div>
										<div className="form-row">
											<div className="col-12 col-md-12 col-lg-12 text-center">
												<label htmlFor="apellido">Apellido</label>
												<div className="icon_input">
													<input
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														className="form-control"
														id="last_name"
														name="last_name"
														placeholder="Apellido"
														required
														value={values.last_name}
													/>
													{Boolean(touched.last_name && errors.last_name) && (
														<div className="error">{touched.last_name && errors.last_name}</div>
													)}
												</div>
											</div>
										</div>
										<div className="form-row mt-3">
											<div className="col-12 col-md-12 col-lg-12 text-center">
												<label htmlFor="edad">¿Cuántos años tienes?</label>
												<div className="icon_input">
													<input
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														className="form-control age"
														id="age"
														name="age"
														maxLength="2"
														placeholder="10"
														required
														value={values.age}
													/>
													{Boolean(touched.age && errors.age) && (
														<div className="error">{touched.age && errors.age}</div>
													)}
												</div>
											</div>
										</div>
										<div className="form-row mt-3">
											<div className="col-12 col-md-12 col-lg-12 text-center">
												<label htmlFor="correo_usuario">Mi correo electrónico</label>
												<div className="icon_input">
													<input
														onBlur={handleBlur}
														onChange={handleChange}
														type="email"
														className="form-control"
														id="email"
														name="email"
														placeholder="Escribir correo..."
														required
														value={values.email}
													/>
													{Boolean(touched.email && errors.email) && (
														<div className="error">{touched.email && errors.email}</div>
													)}
												</div>
											</div>
										</div>
										<div className="form-row mt-3">
											<div className="col-12 col-md-12 col-lg-12 text-center">
												<label htmlFor="correo_tutor">Correo electrónico de mi tutor</label>
												<div className="icon_input">
													<input
														onBlur={handleBlur}
														onChange={handleChange}
														type="email"
														className="form-control"
														id="mentor"
														name="mentor"
														placeholder="Escribir correo..."
														value={values.mentor}
													/>
													{Boolean(touched.mentor && errors.mentor) && (
														<div className="error">{touched.mentor && errors.mentor}</div>
													)}
												</div>
											</div>
										</div>
										<div className="form-row mt-3">
											<div className="col-12 col-md-12 col-lg-12 text-center">
												<Button
													type="submit"
													title="Guardar"
													color="purple"
													disabled={isSubmitting}
												/>
											</div>
										</div>
									</form>
								)}
							</Formik>
						</div>

						<div className="col-12 col-md-8 col-lg-8">
							<div className="bg_container_avatar_customize">
								{loading && (
									<div className="pt-5 pb-5 text-center loading_avatar">Cargando . . . </div>
								)}
								{buyed == false ? (
									gender && (
										<AvatarMaker
											setLoading={setLoading}
											reinitMaker={reinitMaker}
											gender={gender}
										/>
									)
								) : (
									<>
										{custom && (
											<div className="row p-4">
												<div className="col-12 col-md-8">
													<img src={`${custom.image}`} alt="" className="img-fluid custom-image" />
												</div>
												<div className="col-12 col-md-4">
													{custom.title && <h3 className="cBlue">{custom.title}</h3>}

													<div className="diamond-left">
														<img src={ic_diamond} width="20%" />
														<span className="points_diamond_left color-black font-weight vag">
															{' '}
															150{' '}
														</span>
														<span className="count_diamond color-black vag">gemas</span>
													</div>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-7 col-md-8 col-lg-8 mb-3">
							<AvatarSlider getCustom={getCustom} isBuyed={isBuyed} initMaker={doReMaker} />
						</div>
						<div className="col-5 col-md-4 col-lg-4 mb-3">
							{me && me.roleId == 3 && schoolInfo && schoolInfo.id && (
								<div className="container_bg_footer">
									<div className="content-institute-detail">
										<div className="content-logo">
											<img
												className="logo-institute"
												src={schoolInfo.image}
												alt={schoolInfo.name}
											/>
										</div>
										<div className="content-istitute-description">
											<h3 className="title-about-institute">Sobre mi instituto</h3>
											<div className="content-info-detail-institute">
												<p className="title-name-institute">{schoolInfo.name}</p>
												<p className="text-classroom-teacher">
													Grado: <span>{schoolInfo.grade}</span> / Profesor:{' '}
													<span>{schoolInfo.teacher}</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Profile;
