import React from 'react';
import { useHistory } from 'react-router';
import Button from '../Button';

function ModalEmailSent({ show, setShow }) {
	const history = useHistory();

	const handleLogin = () => {
		history.push('/login');
	};

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalSuccessEmail"
				tabIndex="-1"
				aria-labelledby="modalSuccessEmailLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content radious-modal">
						<div className="modal-body">
							<p className="textMessage vag text-blue text-center">
								Ahora, inicia sesión y configura tu cuenta para empezar a jugar.
							</p>
						</div>
						<div className="modal-footer buttonFooterWLogin text-center">
							<Button title="Iniciar Sesión" color="primary" action={handleLogin} width="auto" />
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalEmailSent;
