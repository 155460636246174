import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import useAuth from '../auth/useAuth';
import Button from './Button';
import { GET_AVATAR_USER } from '../services/queries';
import logoKulture from '../assets/img/logo-kulture.png';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Step4(props) {
	const history = useHistory();

	const [avatar, setAvatar] = useState(false);
	//const [me, setMe] = useState([]);
	const user = useAuth();
	const me = user.getUser();

	const begin = () => {
		history.push('/');
	};

	const [getAvatarUser, resultGetAvatarUser] = useLazyQuery(GET_AVATAR_USER, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.getAvatarUser) {
					console.log('RESULTADO: GET_AVATAR_USER', result.getAvatarUser);
					setAvatar(result.getAvatarUser);
				}
			}
		},
	});

	useEffect(() => {
		console.log('ME => ', me.id);
		if (me && me.id) {
			console.log('getAvatarUser: ', {
				id: parseInt(me.id),
			});
			getAvatarUser({
				variables: {
					id: parseInt(me.id),
				},
			});
		}
	}, [me]);

	if (!me) return false;

	return (
		<div className="setup-content " id="step-4">
			<div className="box_login_row">
				<div className="box_login_container">
					<div className="col-md-12">
						<div className="content_step">
							<div className="logo mt-5 text-center">
								<img src={logoKulture} alt="Kulture4Kids" />
							</div>
							<h6 className="title_first_step text-center mt-3">¡Está todo listo para aprender!</h6>
							<div className="row mt-5">
								<div className="container-resume-profile bg_resume">
									<div className="col-md-4 col-lg-4">
										{avatar && (
											<div className="content-avatar">
												<img src={`${process.env.REACT_APP_URL_IMAGES}${avatar.image}`} />
											</div>
										)}
									</div>
									<div className="col-md-8 col-lg-8">
										<div className="row">
											<div className="col-md-10 offset-md-8 text-left">
												{me && (
													<div className="container-resume-text">
														<p className="text-resume-name truncate-resume-name">
															{me.first_name} {me.last_name}
														</p>
														<p className="text-resume-age">{me.age}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12 mt-5 mb-5 text-center">
								{/*}
								<Button
									title="Regresar"
									color="outline"
									width="auto"
									action={() => goToStep(3)}
									css="px-4 mr-sm-4"
								/>
								{*/}

								<Button title="Empezar" color="primary" width="auto" action={begin} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Step4;
