import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../auth/useAuth';
import Layout from './Layout';

import ic_arrow_right from '../assets/img/icons/ic_arrow_right.svg';
import item_product_default from '../assets/img/item_product_default.png';
import ic_diamond from '../assets/img/icons/ic_diamond.png';
import ic_star from '../assets/img/icons/ic_star.png';
import ic_winner from '../assets/img/icons/ic_winner.png';
import FooterAds from '../components/FooterAds';
import StarPoints from '../components/StarPoints';
import DiamondPoints from '../components/DiamondPoints';
import React, { useEffect, useState, useRef } from 'react';
import ModalNoGems from '../components/modals/ModalNoGems';
import Button from '../components/Button';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ImageLazy from '../components/ImageLazy';
import { GET_AD, GET_POINTS_AND_GEMS, LIST_AVATARS, MY_POINTS, TOP10 } from '../services/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { BUY_AVATAR, CREATE_AD_CLICK, CREATE_AD_IMPRESSION } from '../services/mutations';
import AvatarUser from '../components/avatar/AvatarUser';
import Messages from '../components/modals/Messages';
import { useIntersection } from '../utils/UseIntersection';

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const SkeletonTable = (props) => {
	return (
		<div className="skeleton skeleton-table">
			<SkeletonTheme>
				<table className="table">
					<tbody>
						{[0, 1, 2, 3, 4, 5].map((element, i) => {
							return (
								<tr key={i}>
									<td width="200" className="">
										<Skeleton count={1} borderRadius={40} width="100%" />
									</td>
									<td width="150" className="text-center">
										<Skeleton
											circle
											count={1}
											borderRadius={50}
											//width={300}
											//inline={true}
											width={40}
											height={40}
										/>
									</td>
									<td>
										<Skeleton count={1} borderRadius={40} width="100%" />
									</td>
									<td>
										<Skeleton count={1} borderRadius={40} width="100%" />
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</SkeletonTheme>
		</div>
	);
};

const SkeletonGems = (props) => {
	return (
		<div className="skeleton skeleton-table">
			<SkeletonTheme>
				{[1, 2, 3].map((element, i) => {
					return (
						<div key={i} className="card card_section">
							<div className="card-body card_tile pb-4">
								<div className="row pl-2 pr-2 mb-2">
									<div className="col-5">
										<Skeleton circle count={1} borderRadius={40} width={70} height={70} />
									</div>
									<div className="col-7">
										<Skeleton
											count={3}
											borderRadius={40}
											//height={12}
											width={'100%'}
										/>
									</div>
								</div>
								<div className="col-12">
									<Skeleton count={1} borderRadius={40} height={50} width={'100%'} />
								</div>
							</div>
						</div>
					);
				})}
			</SkeletonTheme>
		</div>
	);
};

const initialPopupMessage = {
	title: 'No cuenta con suficientes gemas para canjear.',
	message: '',
};

function Points() {
	const [showRedeemAvatar, setShowRedeemAvatar] = useState(false);
	const [showAvatarRedeemed, setShowAvatarRedeemed] = useState(false);
	const [showNoGems, setShowNoGems] = useState(false);

	const [avatarChoosed, setAvatarChoosed] = useState([]);

	const initialPointsState = {
		points: 0,
		gems: 0,
	};
	const [loginSwiper5, setLoginSwiper5] = useState(null);
	const [pointsLoaded, setPointsLoaded] = useState(false);
	const [gemsLoaded, setGemsLoaded] = useState(false);
	const [topten, setTopTen] = useState([]);
	const [myPoints, setPoints] = useState(null);
	const [myGems, setGems] = useState(null);
	const [avatars, setAvatars] = useState([]);
	const [popup, setPopup] = useState(false);
	const [popupMessage, setPopupMessage] = useState(initialPopupMessage);

	const history = useHistory();
	const location = useLocation();
	const previusObjectURL = location.state?.from;
	const auth = useAuth();
	const [ad5, setAd5] = useState([]);

	const refAd5 = useRef();
	const inViewportAd5 = useIntersection(refAd5, '0px');

	const [newAvatar, resultBuyAvatar] = useMutation(BUY_AVATAR, {
		onCompleted(data) {
			if (data.buyAvatar) {
				console.log('RESULTADO: BUY_AVATAR: ', data.buyAvatar);
				setShowRedeemAvatar(false);
				setShowAvatarRedeemed(true);
				getAllAvatars();
			} else {
				setShowRedeemAvatar(false);
				setPopup(true);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const [getPG, resultPG] = useLazyQuery(GET_POINTS_AND_GEMS, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				console.log(result);
				if (result.getPG) {
					console.log('RESULTADO: GET_POINTS_AND_GEMS: ', result.getPG);
					setGems(result.getPG.gems);
					setPoints(result.getPG.points);
				}
			}
		},
	});

	const [getAvatars, resultAvatars] = useLazyQuery(LIST_AVATARS, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				console.log(result);
				if (result.listAvatars) {
					console.log('RESULTADO: AVATARS: ', result.listAvatars);
					setAvatars(result.listAvatars);
				}
			}
		},
	});

	const [getPoints, resultGetPoints] = useLazyQuery(MY_POINTS, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				//console.log(result);
				if (result.myPoints) {
					//console.log('RESULTADO: Points: ', result.myPoints[0]);
					if (result.myPoints[0]) setPoints(result.myPoints[0]);
					else setPoints(initialPointsState);
				}
			}
		},
	});

	const [getTopTen, resultGetTopTen] = useLazyQuery(TOP10, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			console.log('result top ten', result);
			if (result) {
				console.log(result);
				if (result.listPoints) {
					console.log('RESULTADO: Top 10: ', result.listPoints);
					setTopTen(result.listPoints);
				}
			}
		},
	});

	const [getAd5, resultAd5] = useLazyQuery(GET_AD, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.ad && result.ad.length > 0) {
					console.log('RESULTADO: GET_AD 5', result.ad);
					setAd5(result.ad);
				}
			}
		},
	});

	const [addClick, resultAddClick] = useMutation(CREATE_AD_CLICK, {
		onCompleted(data) {
			console.log(data);
			if (data.createAdClick) {
				console.log('CREATE_AD_CLICK: ', data);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const [addImpression, resultAddImpression] = useMutation(CREATE_AD_IMPRESSION, {
		onCompleted(data) {
			console.log(data);
			if (data.createAdImpressions) {
				console.log('CREATE_AD_IMPRESSION: ', data);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const createAddClick = (id) => {
		console.log('createAddClick: ', id);
		addClick({
			variables: {
				ads_id: parseInt(id),
			},
		});
	};

	const handleLogout = () => {
		auth.logout();
		history.push(previusObjectURL || '/login');
	};

	const getPointsGems = () => {
		getPG();
	};

	const getAllAvatars = () => {
		getAvatars();
	};

	useEffect(() => {
		setTimeout(() => {
			setPointsLoaded(true);
		}, 700);

		setTimeout(() => {
			setGemsLoaded(true);
		}, 1200);
		//setShowRedeemAvatar(true)
		//setShowAvatarRedeemed(true)
		//setShowNoGems(true)

		getTopTen();
		getAllAvatars();
		getPointsGems();

		getAd5({
			variables: {
				position: 8,
			},
		});
	}, []);

	useEffect(() => {
		if (ad5.length > 0) {
			Swiper.use([Navigation, Pagination, Autoplay]);

			let tempSwiper = new Swiper('.ad5-swiper', {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 8000,
				},
				watchSlidesVisibility: true,
				on: {
					init: function (tempSwiper) {
						const index_currentSlide = tempSwiper.realIndex;
						const currentSlide = tempSwiper.slides[index_currentSlide];
						let dataID = currentSlide.getAttribute('data-id');

						if (inViewportAd5) {
							console.log('IMPRESSION AD 5 ADDED ON ----- INIT DATA.', {
								ads_id: parseInt(dataID),
							});
							addImpression({
								variables: {
									ads_id: parseInt(dataID),
								},
							});
						}
					},
				},
			});
			setLoginSwiper5(tempSwiper);

			tempSwiper.on('slideChange', function () {
				const index_currentSlide = tempSwiper.realIndex;
				const currentSlide = tempSwiper.slides[index_currentSlide];
				let dataID = currentSlide.getAttribute('data-id');
				//console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

				if (inViewportAd5) {
					console.log('IMPRESSION AD 5 ADDED.', {
						ads_id: parseInt(dataID),
					});
					addImpression({
						variables: {
							ads_id: parseInt(dataID),
						},
					});
				}
			});
		}
	}, [ad5]);

	const buyAvatar = () => {
		console.log('newAvatar: ', {
			id: parseInt(avatarChoosed.id),
			howMuch: parseInt(avatarChoosed.gems),
		});
		newAvatar({
			variables: {
				id: parseInt(avatarChoosed.id),
				howMuch: parseInt(avatarChoosed.gems),
			},
		});
	};

	if (!topten) return false;

	return (
		<Layout>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center bg_x2 screen_points">
				<div className="containers container_main_view h-100">
					<div className="row mb-4">
						<div className="col-12 col-md-9 col-lg-8 col-xl-9">
							<div className="container_bar_points">
								<div className="row h-100">
									<div className="col-6 col-md-7 col-lg-6 pt-0 pt-md-3 pl-5 align-self-center">
										<div className="container_my_points">
											<h3 className="title_head_my_points_ranking">Mis puntos</h3>
											<p className="subtitle_head_my_points_ranking">
												A continuación se muestra un total de los puntos que has acumulado.
											</p>
										</div>
									</div>

									<div className="col-6 col-md-5 col-lg-6 h-100 align-self-center pl-lg-0">
										<div className="container_diamond_progressbar_x1">
											<div className="row h-100">
												<div className="col-6 align-self-center">
													<div className="points-left">
														<img className="progress_img_star" src={ic_star} />
														<span className="points_star_left_x2">{myPoints ? myPoints : 0}</span>
													</div>
												</div>
												<div className="col-6 align-self-center border-middle">
													<div className="diamond-right">
														<img className="progress_img_diamond" src={ic_diamond} />
														<span className="points_diamond_left_x2">{myGems ? myGems : 0}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-3 col-md-3 col-lg-4 col-xl-3 d-none d-md-block">
							{ad5 && ad5.length > 0 ? (
								<div className="swiper ad5-swiper slider swiper-container">
									<div className="swiper-wrapper">
										{ad5.map((item, index) => (
											<div key={index} className="swiper-slide" data-id={item.id}>
												<a
													href={ad5.url}
													onClick={() => createAddClick(item.id)}
													target="blank"
													className="text-decoration-none"
												>
													<img src={item.image} alt="Ads" className="img-fluid" />
												</a>
											</div>
										))}
									</div>

									<div className="swiper-pagination"></div>
								</div>
							) : null}
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-9 col-lg-8 mb-1 col-xl-9">
							<div className="card_radius_content_x1 bg-white border-radius-30">
								{pointsLoaded == true ? (
									<div className="card">
										<div className="card-body">
											<h3 className="title-head-top-best text-blue">Los 10 mejores</h3>
										</div>
										<div className="content_list_scroll_view">
											<div className="content_list_scroll" style={{ height: '447px' }}>
												<div className="table-responsive">
													<table className="table table-points">
														<thead>
															<tr className="tbl_rankin">
																<th scope="col">
																	<div className="title_table">Posición</div>
																</th>
																<th scope="col">
																	<div className="title_table">Avatar</div>
																</th>
																<th scope="col">
																	<div className="title_table table_aling">Nombre</div>
																</th>
																<th scope="col">
																	<div className="title_table text-center">Puntos obtenidos</div>
																</th>
															</tr>
														</thead>
														<tbody>
															{topten.map((item, index) => (
																<tr>
																	<td sc ope="row">
																		<div className="container_num_rank_position ranking_x1">
																			{index + 1 == 1 && (
																				<ImageLazy
																					src={ic_winner}
																					className="img-fluid ranking_winner"
																					alt=""
																					width="60"
																					height="60"
																				/>
																			)}
																			{index + 1}
																		</div>
																	</td>
																	<td>
																		<div>
																			<AvatarUser id={item.id} />
																		</div>
																	</td>
																	<td>
																		<div className="individual_avatar_name">{`${
																			item.first_name ? item.first_name : ''
																		} ${item.last_name ? item.last_name : ''}`}</div>
																	</td>
																	<td>
																		<div className="row">
																			<StarPoints points={item.points ? item.points : 0} />
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								) : (
									<SkeletonTable />
								)}
							</div>
						</div>

						<div className="col-7 col-md-3 col-lg-4 col-xl-3 mt-4 mt-md-auto">
							<div className="card card_radius_content_x1">
								<div className="card-body">
									<h3 className="title-head-top-best text-blue-dark text-center">
										Canjea tus gemas
									</h3>
									<p className="subtitle_available_gems vag mb-0">
										Gemas disponibles: <span className="count_available_gems">{myGems}</span>
									</p>
								</div>
								<div className="content_list_scroll_view">
									<div className="content_list_scroll" style={{ height: '447px' }}>
										{gemsLoaded == true ? (
											<>
												{avatars.map((item) => (
													<div className="card card_sectios">
														<div className="card-body card_tile">
															<div className="row pl-2 pr-2 mb-2">
																<div className="media position-relative mb-2">
																	<img
																		width="80"
																		heigth="80"
																		src={`${item.image}`}
																		className="img-fluid avatar_redeem_item"
																	/>
																	<div className="media-body pl-2">
																		<h5 className="avatar_title mb-0">Avatar</h5>
																		<span className="avatar_name">{item.title}</span>
																		<DiamondPoints points={item.gems} />
																	</div>
																</div>
															</div>
															<div className="text-center mt-2">
																<Button
																	title="Comprar"
																	color="purple"
																	width="full"
																	action={() => {
																		setShowRedeemAvatar(true);
																		setAvatarChoosed(item);
																	}}
																/>
															</div>
														</div>
													</div>
												))}
											</>
										) : (
											<SkeletonGems />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<FooterAds />

			{popupMessage && (
				<Messages
					show={popup}
					setShow={setPopup}
					title={popupMessage.title}
					message={popupMessage.message}
				/>
			)}

			{showRedeemAvatar && (
				<>
					<div
						className={'modal fade ' + (showRedeemAvatar ? 'show modal-block' : 'modal-none')}
						id="modalConfirmPurchase"
						tabIndex="-1"
						aria-labelledby="modalConfirmPurchaseLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content radious-modal">
								<div className="modal-header notBrHeader mb-top15">
									<h5 className="titleModalHeader-2">¿Canjear avatar?</h5>
								</div>
								<div className="modal-body">
									<div className="d-flex justify-content-center mb-2">
										<div className="row">
											<div className="col-5">
												<img
													//src={`${process.env.REACT_APP_URL_IMAGES}${avatarChoosed.image}`}
													src={`${avatarChoosed.image}`}
													className="img-fluid avatar_redeem_item"
												/>
											</div>
											<div className="col-7 p-0">
												<h6 className="card-subtitle mt-2 avatar_title">Avatar</h6>
												<h6 className="card-subtitle my-1 avatar_name text-truncate">
													{avatarChoosed.title}
												</h6>
												<div className="container_bagde_points_x2">
													<div className="row">
														<img src={ic_diamond} className="diamond_redeem_img" />
														<div className="points_redeem_badge">{avatarChoosed.gems}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer buttonFooterWLogin mt-0 mb-3 mt-md-3">
									<div className="row">
										<div className="col-6 col-md-6">
											<Button
												title="Cancelar"
												color="red"
												action={() => setShowRedeemAvatar(false)}
											/>
										</div>
										<div className="col-6 col-md-6">
											<Button title="Confirmar canje" color="purple" action={() => buyAvatar()} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'modal-backdrop fade ' + (showRedeemAvatar ? 'show' : '')}></div>
				</>
			)}

			{showAvatarRedeemed && (
				<>
					<div
						className={'modal fade ' + (showAvatarRedeemed ? 'show modal-block' : 'modal-none')}
						id="modalSuccessfullyPurchased"
						tabIndex="-1"
						aria-labelledby="modalSuccessfullyPurchasedLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content radious-modal">
								<div className="modal-header notBrHeader mb-top15">
									<h5 className="titleModalHeader-2 text-title-modal-danger">¡Avatar Canjeado!</h5>
								</div>
								<div className="modal-body">
									<div className="bg_congratulations">
										<div className="d-flex justify-content-center mb-2">
											<div className="row">
												<img
													//src={`${process.env.REACT_APP_URL_IMAGES}${avatarChoosed.image}`}
													src={`${avatarChoosed.image}`}
													className="img-fluid avatar_redeem_item"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer buttonFooterWLogin mb-3">
									<div className="row">
										<div className="col-12 col-md-6">
											<Button
												title="Guardar Avatar"
												color="red"
												action={() => setShowAvatarRedeemed(false)}
											/>
										</div>
										<div className="col-12 col-md-6">
											<Button
												title="Usar Avatar"
												color="purple"
												action={() => setShowAvatarRedeemed(false)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'modal-backdrop fade ' + (showAvatarRedeemed ? 'show' : '')}></div>
				</>
			)}

			<ModalNoGems show={showNoGems} setShow={setShowNoGems} />
		</Layout>
	);
}

export default Points;
