import React, { useEffect, useState } from 'react';

import diamond from '../../assets/img/icons/ic_diamond_purple.png';
import slide_puzzle_3 from '../../assets/img/slide_puzzle_3.png';
import useNavigate from '../../utils/useNavigate';
import Button from '../Button';

function ModalFinishQuiz({ show, setShow, info, level }) {
	const navigate = useNavigate();
	const [extraGems, setExtraGems] = useState(null);

	const goTo = (url) => {
		cleanLS();
		navigate.goTo(url);
	};

	useEffect(() => {
		if (
			localStorage.getItem('k4k_gems_count_' + info.id) &&
			localStorage.getItem('k4k_gems_count_' + info.id) > 0
		)
			setExtraGems(localStorage.getItem('k4k_gems_count_' + info.id));
	}, [localStorage.getItem('k4k_gems_count_' + info.id)]);

	const cleanLS = () => {
		localStorage.setItem('k4k_gems_count_' + info.id, null);
	};

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalQuizFinish"
				tabIndex="-1"
				aria-labelledby="modalQuizFinishLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content radious-modal">
						<div className="container_work">
							<div className="container_work_image">
								<img className="work_img" src={info.thumbnail} />
							</div>
						</div>

						<div className="modal-header notBrHeader p-0">
							<h5 className="title_quiz_completed">¡Quiz completado!</h5>
						</div>
						<div className="modal-body">
							<div className="container_finish_completed_quiz">
								<h1 id="title_work_name" className="title_work_name">
									{info.name}
								</h1>
								<h3 id="title_author_name" className="title_author_name">
									{`${info.artist_first_name} ${info.artist_last_name}`}
								</h3>
								{extraGems && (
									<div className="container_description_finish_completed_quiz">
										<img className="diamond_purple_img" src={diamond} />
										<p className="text_points_extras">+{extraGems} Gemas Extras</p>
									</div>
								)}
							</div>
						</div>
						<div className="modal-footer buttonFooterWLogin mb-3 text-center">
							<Button
								title="Finalizar"
								color="purple"
								action={() => goTo('/puzzle')}
								width="auto"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalFinishQuiz;
