import Layout from './Layout';
import React, { useEffect, useState, useRef } from 'react';

import ModalFinishQuiz from '../components/modals/ModalFinishQuiz';
import ModalFinishPuzzle from '../components/modals/ModalFinishPuzzle';
import ModalQuiz from '../components/modals/ModalQuiz';
import PointsGems from '../components/PointsGems';
import logoKulture from '../assets/img/logo-kulture.png';
import closePuzzle from '../assets/img/puzzle/close.png';
import preview from '../assets/img/puzzle/preview.png';
import reset from '../assets/img/puzzle/reset.png';
import useNavigate from '../utils/useNavigate';
import Button from '../components/Button';
import { useParams } from 'react-router';
import { GET_ARTWORK, GET_PG_LEVEL } from '../services/queries';
import { useLazyQuery } from '@apollo/client';
import close_img from '../assets/img/icons/i-close.png';
import ModalInfoPuzzle from '../components/modals/ModalInfoPuzzle';
import Player from '../components/Audio';

function PuzzleGame() {
	const navigate = useNavigate();
	const myRef = useRef();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	const { id, pieces } = useParams();
	const [showBio, setShowBio] = useState(false);
	const [showFinishQuiz, setShowFinishQuiz] = useState(false);
	const [showQuiz, setShowQuiz] = useState(false);
	const [artwork, setArtwork] = useState([]);
	const [level, setLevel] = useState([]);
	const [audioTrack, setAudioTrack] = useState([]);
	const [showFinishPuzzle, setShowFinishPuzzle] = useState(false);

	const [getPG, resultGetPG] = useLazyQuery(GET_PG_LEVEL, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				console.log('RESULTADO: GET_PG_LEVEL: ', result.getPGLevel);
				setLevel(result.getPGLevel);
			}
		},
	});

	const [getArtwork, resultGetArtwork] = useLazyQuery(GET_ARTWORK, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				console.log('RESULTADO: GET_ARTWORK: ', result.artwork[0]);
				!result.artwork[0] ? setArtwork(result.artwork) : setArtwork(result.artwork[0]);
			}
		},
	});

	useEffect(() => {
		if (pieces) {
			getPG({
				variables: {
					pieces: parseInt(pieces),
				},
			});
		}
	}, [pieces]);

	useEffect(() => {
		if (level) {
			getArtwork({
				variables: {
					id: parseInt(id),
				},
			});

			setTimeout(() => {
				//setShowQuiz(true);
				//setShowFinishPuzzle(true);
			}, 2000);
		}
	}, [id]);

	useEffect(() => {
		if (artwork && pieces) {
			if (artwork.audio) setAudioTrack(artwork.audio);

			window.jQuery(document).ready(function ($) {
				new jigsaw.Jigsaw({
					defaultImage: artwork.image,
					image: artwork.image,
					spread: 0.8,
					defaultPieces: pieces,
					numberOfPieces: [10, 20, 30, 40, 50, 60, 100],
					piecesNumberTmpl: '%d Pieces',
					maxWidth: 800,
					maxHeight: 600,
					shuffled: true,
					border: false,
					offsetTop: 0,
					rotatePieces: false,
					squarePieces: false,
				});
				let canvas = document.getElementById('image-preview');
				canvas.className = 'show';

				setTimeout(() => {
					canvas.className = 'hide';
				}, 3000);
			});
		}
	}, [pieces, artwork]);

	useEffect(() => {
		localStorage.setItem('k4k_puzzle', '');
		localStorage.setItem('k4k_puzzle_time', '');
		let x = setInterval(() => {
			if (localStorage.getItem('k4k_puzzle') == 'done') {
				setShowFinishPuzzle(true);
			}
		}, 1000);

		return () => {
			clearInterval(x);
		};
	}, []);

	useEffect(() => {
		console.log('audioTrack', audioTrack);
	}, [audioTrack]);

	if (artwork.length <= 0) return null;

	return (
		<Layout>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 bg_x2 h-100 puzzle_container">
				<div id="game-options" className="containers container_main_view">
					<div className="row align-items-center mt-1 mb-2 ml-3 mr-3">
						<div className="col-3 col-md-1 col-lg-4 col-xl-3 align-self-center">
							<img className="logo_k4k_x1 img-fluid" src={logoKulture} alt="Kulture4Kids" />
						</div>

						<div className="col-7 col-md-3 col-lg-4 col-xl-6 align-self-center text-right justify-content-center d-md-flex">
							<ul>
								<li className="text-center time_block">
									<span>Tiempo</span>
									<b id="clock" className="button">
										00:00:00
									</b>
								</li>
							</ul>
						</div>

						<div className="col-2 col-md-4 col-lg-4 col-xl-3 text-right align-self-center text-right justify-content-end d-flex">
							<ul>
								<li>
									<a
										style={{ background: 'url(' + reset + ') no-repeat center center' }}
										id="JIGSAW_SHUFFLE"
										className="button left"
										title="Shuffle"
									></a>
								</li>
								<li>
									<a
										style={{ background: 'url(' + preview + ') no-repeat center center' }}
										id="SHOW_PREVIEW"
										className="button middle"
										title="Preview"
									></a>
								</li>
								<li>
									<a onClick={() => goTo('/puzzle')}>
										<img src={closePuzzle} />
									</a>
								</li>
							</ul>
						</div>

						<div className="col-12 col-md-12 col-lg-12">
							<ul>
								<li style={{ display: 'none' }}>
									<select id="set-parts" selected-index="8"></select>
								</li>

								<li style={{ display: 'none !important' }} id="create">
									<a className="button add" id="SHOW_FILEPICKER" title="Create puzzle">
										Create puzzle
									</a>
								</li>
							</ul>
							<br className="clear" />

							<div id="canvas-wrap">
								<canvas id="canvas"></canvas>
								<canvas className="hide" id="image"></canvas>
								<canvas className="hide" id="image-preview"></canvas>
							</div>

							<div className="hide" id="overlay"></div>
							<div id="modal-window" className="hide">
								<div id="modal-window-msg"></div>
								<a id="modal-window-close" className="button">
									Close
								</a>
							</div>

							<div id="congrat" className="hide">
								<h1>Congratulations!</h1>
								<h2>You solved it in</h2>
								<h3>
									<span id="time"></span>
								</h3>
							</div>

							<div className="hide" id="create-puzzle">
								<h1>Choose an image</h1>
								<form id="add-image-form">
									<input type="file" id="image-input" />
									<p id="image-error">that's not an image</p>
									<p id="dnd">
										<i>Or drag one from your computer</i>
									</p>
								</form>
							</div>

							<div id="help" className="hide">
								<h2>How to play</h2>
								<ul>
									<li>Change the number of pieces with the selector on the top.</li>

									<li>Use left/right arrows, or right click to rotate a piece.</li>

									<li>Toggle between edge or middle pieces:</li>
								</ul>

								<h3>Good luck.</h3>
							</div>

							<form className="hide" method="post" id="redirect-form">
								<input type="text" name="time" id="t" />
								<input type="text" name="parts" id="p" />
							</form>
							<iframe className="hide" src="about:blank" id="save-score" name="save-score"></iframe>
						</div>

						<div className="col-7 col-md-5 col-lg-5 mb-1 mt-md-4 mt-5 align-self-center d-flex justify-content-center justify-content-lg-start">
							{artwork.length != 0 && (
								<Button
									title="DESCUBRE MÁS SOBRE ESTA OBRA"
									color="primary"
									width="auto"
									action={() => {
										setShowBio(true);
									}}
								/>
							)}
						</div>

						<div className="col-5 mt-5 mb-1 mt-md-4 col-md-3 col-lg-3 text-center align-self-center">
							{audioTrack && <Player url={audioTrack} />}
						</div>

						<div className="col-12 col-md-4 col-lg-4 align-self-center mb-1 mt-3 mt-lg-4 text-right d-flex justify-content-center justify-content-md-end">
							<PointsGems text={false} />
						</div>
					</div>
				</div>
			</div>

			<ModalInfoPuzzle show={showBio} setShow={setShowBio} info={artwork} />

			<ModalFinishPuzzle
				show={showFinishPuzzle}
				setShow={setShowFinishPuzzle}
				quiz={setShowQuiz}
				info={artwork}
				level={level}
			/>

			<ModalQuiz
				id={artwork.id}
				show={showQuiz}
				setShow={setShowQuiz}
				quiz={setShowFinishQuiz}
				info={artwork}
				level={level}
			/>

			<ModalFinishQuiz
				show={showFinishQuiz}
				setShow={setShowFinishQuiz}
				info={artwork}
				level={level}
			/>
		</Layout>
	);
}

export default PuzzleGame;
