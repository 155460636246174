import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import logoKulture from './assets/img/logo-kulture.png';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

require('dotenv').config();

const httpLink = createUploadLink({
	uri: process.env.REACT_APP_API_URL_TEST
		? process.env.REACT_APP_API_URL_TEST
		: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
	const token = sessionStorage.getItem('session');

	/* console.log('TOKEN - - - - - - - - - - -');
	console.log(token); */

	if (!token) return false;

	return {
		headers: {
			...headers,
			'x-token': token,
		},
	};
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	//link: httpLink
	link: authLink.concat(httpLink),
});

const LoadingSite = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, []);

	if (loading == true) {
		return (
			<div className="cell">
				<div className="wrapper">
					<img src={logoKulture} alt="" />
					<div className="spinner spinner5"></div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ApolloProvider client={client}>
				<App />
				<LoadingSite />
			</ApolloProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
