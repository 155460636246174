import { useHistory, useLocation } from 'react-router-dom';

const useNavigate = () => {
	const history = useHistory();
	const location = useLocation();
	const previusObjectURL = location.state?.from;

	const goTo = (url) => {
		history.push(previusObjectURL || url);
	};

	return { goTo };
};

export default useNavigate;
