function LoaderSpinner({ height = 350 }) {
	return (
		<div className="cell">
			<div className="internal-wrapper" style={{ height: height }}>
				<div className="spinner spinner2"></div>
			</div>
		</div>
	);
}

export default LoaderSpinner;
