import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useState } from 'react';

function ImageLazy(props) {
	const img = new Image();
	const { width, height } = props;
	const [loading, setLoading] = useState(true);

	const loaded = () => {
		console.log('image loaded');
	};

	useEffect(() => {
		console.log(props.center);
		img.src = props.src;
		//img.addEventListener('load', loaded);

		$(img)
			.on('load', function () {
				setTimeout(() => {
					setLoading(false);
				}, 1500);
				//console.log("image loaded correctly");
			})
			.on('error', function () {
				//console.log("error loading image");
			});

		/*return () => {
            img.removeEventListener('load', loaded);
        }*/
	}, []);

	if (loading == false) {
		return <img {...props} />;
	} else {
		return (
			<div
				className={
					'skeleton skeleton-image ' + (props.center == true ? 'skeleton-image-center' : '')
				}
				style={{
					width: width + 'px',
					height: height + 'px',
				}}
			>
				<SkeletonTheme>
					<Skeleton
						circle
						height="100%"
						//width={300}
						//inline={true}
					/>
				</SkeletonTheme>
			</div>
		);
	}
}

export default ImageLazy;
