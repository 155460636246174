import React from 'react';

import avatar_1 from '../../assets/img/avatar_1.png';
import ic_diamond from '../../assets/img/icons/ic_diamond.png';
import Button from '../Button';

function ModalRedeemAvatar({ show, setShow }) {
	const handle = () => {
		history.push('/login');
	};

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalConfirmPurchase"
				tabIndex="-1"
				aria-labelledby="modalConfirmPurchaseLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content radious-modal">
						<div className="modal-header notBrHeader mb-top15">
							<h5 className="titleModalHeader-2">¿Canjear avatar?</h5>
						</div>
						<div className="modal-body">
							<div className="d-flex justify-content-center mb-2">
								<div className="row">
									<div className="col-5">
										<img src={avatar_1} className="img-fluid avatar_redeem_item" />
									</div>
									<div className="col-7 p-0">
										<h6 className="card-subtitle mt-2 avatar_title">Avatar</h6>
										<h6 className="card-subtitle my-1 avatar_name text-truncate">Salvador Dalí</h6>
										<div className="container_bagde_points_x2">
											<div className="row">
												<img src={ic_diamond} className="diamond_redeem_img" />
												<div className="points_redeem_badge">250</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer buttonFooterWLogin mt-0 mb-3 mt-md-3">
							<div className="row">
								<div className="col-6 col-md-6">
									<Button title="Cancelar" color="red" action={() => setShow(false)} />
								</div>
								<div className="col-6 col-md-6">
									<Button title="Confirmar canje" color="purple" action={() => setShow(false)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalRedeemAvatar;
