import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import img_avatar from '../assets/img/avatar_woman.png';
import img_profile from '../assets/img/icons/profile.png';
import img_points from '../assets/img/icons/points.png';
import img_puzzle from '../assets/img/icons/puzzle.png';
import img_shop from '../assets/img/icons/shop.png';
import img_logout from '../assets/img/icons/logout.png';
import ModalLogout from '../components/modals/ModalLogout';
import ImageLazy from '../components/ImageLazy';
import { GET_AVATAR } from '../services/queries';
import { useLazyQuery } from '@apollo/client';
import useAuth from '../auth/useAuth';
import AvatarUser from '../components/avatar/AvatarUser';

function Sidebar({ sidebar }) {
	const [show, setShow] = useState(false);
	const [me, setMe] = useState([]);

	const user = useAuth();

	useEffect(() => {
		setMe(user.getUser());
	}, []);

	return (
		<React.Fragment>
			<nav
				id="sidebarMenu"
				className={`col-2 col-md-3 col-lg-1 col-xl-2 d-md-block bg-sidebar-primary sidebar sidebar-hg ${
					sidebar == true ? 'sidebar-opened' : ''
				}`}
			>
				<div className="sidebar-sticky pt-3">
					<ul className="nav flex-column text-center align-items-center">
						<div className="col-lg-12 mb-4 my-avatar">
							<Link to="/">
								<AvatarUser />

								<h2 className="welcome-text mt-2">¡Hola!</h2>
								{me && <p className="welcome-text">{me.first_name}</p>}
							</Link>
						</div>
						<li className="nav-item"></li>
						<li className="nav-item">
							<Link className="steps step1" to="/profile">
								<div className="nav-link">
									<div className="btn_sidebar btn_profile">
										<img className="" src={img_profile} alt="" />
									</div>
									<p className="font-weight-bold">Mi perfil</p>
								</div>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="steps step2" to="/points">
								<div className="nav-link">
									<div className="btn_sidebar btn_points">
										<img className="" src={img_points} alt="" />
									</div>
									<p className="font-weight-bold">Mis puntos</p>
								</div>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="steps step3" to="/puzzle">
								<div className="nav-link">
									<div className="btn_sidebar btn_puzzle">
										<img className="" src={img_puzzle} alt="" />
									</div>
									<p className="font-weight-bold">Rompecabezas</p>
								</div>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="steps step4" to="/shop">
								<div className="nav-link">
									<div className="btn_sidebar btn_shop">
										<img className="" src={img_shop} alt="" />
									</div>
									<p className="font-weight-bold">Tienda</p>
								</div>
							</Link>
						</li>
						<li className="nav-item">
							<button className="steps step5" onClick={() => setShow(true)}>
								<div className="nav-link">
									<div className="btn_logout">
										<img className="" src={img_logout} alt="" />
									</div>
									<p className="font-weight-bold">Cerrar Sesión</p>
								</div>
							</button>
						</li>
					</ul>
					<ul className="nav flex-column mb-2">
						<li className="nav-item">
							<div className="nav-link pl-lg-0 pr-lg-0 px-0">
								<div className="col-lg-12 text-center copy_sidebar">
									Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021
								</div>
							</div>
						</li>
					</ul>
				</div>
			</nav>

			<ModalLogout show={show} setShow={setShow} />
		</React.Fragment>
	);
}

export default Sidebar;
