import React from 'react';
import close from '../../assets/img/icons/i-close.png';
import useNavigate from '../../utils/useNavigate';

function ModalResetPassword({ show, setShow }) {
	const navigate = useNavigate();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalRememberPassword"
				tabIndex="-1"
				aria-labelledby="modalRememberPasswordLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content radious-modal">
						<div className="modal-header notBrHeader mb-top15">
							<h5 className="titleModalHeader" id="exampleModalLabel">
								Restaurar Clave Secreta
							</h5>
							<button
								onClick={() => goTo('login')}
								type="button"
								className="btn-close closeBtnModal"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<img src={close} />
							</button>
						</div>
						<div className="modal-body">
							<p className="textMessage vag text-center text-blue">
								Revisa tu correo electrónico y sigue los pasos para restaurar tu contraseña.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalResetPassword;
