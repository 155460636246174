import { gql } from '@apollo/client';

export const SIGNIN = gql`
	mutation ($login: String!, $password: String!, $roleId: Int) {
		signIn(login: $login, password: $password, roleId: $roleId) {
			token
		}
	}
`;

export const UPDATE_INFO_USER = gql`
	mutation (
		$first_name: String
		$last_name: String
		$age: String
		$password: String
		$gender_id: Int
		$mentor: String
		$email: String
	) {
		updateUser(
			first_name: $first_name
			last_name: $last_name
			age: $age
			password: $password
			gender_id: $gender_id
			mentor: $mentor
			email: $email
		) {
			first_name
			last_name
			email
			age
			gender_id
			mentor
		}
	}
`;

export const UPDATE_GENDER = gql`
	mutation ($gender_id: Int) {
		updateUser(gender_id: $gender_id) {
			gender_id
		}
	}
`;

export const CREATE_AVATAR = gql`
	mutation ($image: String!) {
		createUserAvatar(image: $image) {
			id
			userId
			image
			createdAt
		}
	}
`;

export const SIGNUP = gql`
	mutation ($mentor: String, $email: String!, $password: String!, $role: Int!) {
		signUp(mentor: $mentor, email: $email, password: $password, role: $role) {
			message
			token
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation ($email: String!) {
		resetPassword(email: $email) {
			result
			forgotPasswordCode
		}
	}
`;

export const NEW_PASSWORD = gql`
	mutation ($id: ID!, $code: String!, $password: String!) {
		newResetPassword(id: $id, code: $code, password: $password) {
			result
			forgotPasswordCode
		}
	}
`;

export const ADD_GAME = gql`
	mutation (
		$artworkId: Int!
		$points: Int!
		$gems: Int!
		$levelId: Int!
		$puzzle_time: String
		$questions: Int!
	) {
		addGame(
			artworkId: $artworkId
			points: $points
			gems: $gems
			levelId: $levelId
			puzzle_time: $puzzle_time
			questions: $questions
		) {
			id
			userId
			artworkId
			points
			gems
			levelId
			createdAt
			questions
			puzzle_time
		}
	}
`;

export const BUY_ART = gql`
	mutation ($userId: Int!, $artworkId: Int!, $gems: Int!) {
		buyArtwork(userId: $userId, artworkId: $artworkId, gems: $gems) {
			id
			userId
			artworkId
			gems
			createdAt
		}
	}
`;

export const ADD_AVATAR = gql`
	mutation ($image: String!, $info: [Info!]!) {
		createUserAvatar(image: $image, info: $info) {
			id
			userId
			info
			image
			createdAt
		}
	}
`;

export const UPDATE_AVATAR = gql`
	mutation ($id: ID!, $image: String!, $info: [Info!]!) {
		updateUserAvatar(id: $id, image: $image, info: $info) {
			id
			userId
			info
			image
			createdAt
		}
	}
`;

export const USE_AVATAR = gql`
	mutation ($id: ID!, $type: String!) {
		useAvatar(id: $id, type: $type)
	}
`;

export const BUY_AVATAR = gql`
	mutation ($id: ID!, $howMuch: Int) {
		buyAvatar(id: $id, howMuch: $howMuch) {
			id
			userId
			avatar_id
			active
			createdAt
		}
	}
`;

export const CREATE_AD_CLICK = gql`
	mutation ($ads_id: Int!) {
		createAdClick(ads_id: $ads_id) {
			id
			user_ip
			ads_id
		}
	}
`;

export const CREATE_AD_IMPRESSION = gql`
	mutation ($ads_id: Int!) {
		createAdImpressions(ads_id: $ads_id) {
			id
			user_ip
			ads_id
		}
	}
`;

export const UPDATE_PG = gql`
	mutation ($points: Int, $gems: Int, $subtract: Boolean) {
		updatePG(points: $points, gems: $gems, subtract: $subtract) {
			message
			result {
				id
				userId
				points
				gems
				createdAt
			}
		}
	}
`;

export const ACTIVE_ACCOUNT = gql`
	mutation ($id: ID!, $code: String!) {
		activeAccount(id: $id, code: $code)
	}
`;

export const DELETE_AVATAR = gql`
	mutation ($id: ID!) {
		deleteUserAvatar(id: $id)
	}
`;
