import Layout from '../Layout';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import backgroundLogin from '../../assets/img/background-login-student.png';
import logoKulture from '../../assets/img/logo-kulture.png';
import avatar_woman from '../../assets/img/avatar_woman.png';
import avatar_men from '../../assets/img/avatar_men.png';
import avatar_default_preview from '../../assets/img/avatar_default_preview.png';
import AvatarMaker from '../../components/avatar/AvatarMaker';
import ModalEmailSent from '../../components/modals/ModalEmailSent';
import Button from '../../components/Button';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { UPDATE_INFO_USER, UPDATE_GENDER, CREATE_AVATAR } from '../../services/mutations';
import { useMutation } from '@apollo/client';
import Step4 from '../../components/StepFour';

require('dotenv').config();

function CreateProfile() {
	const history = useHistory();
	const location = useLocation();
	const previusObjectURL = location.state?.from;

	const [step, setStep] = useState(0);
	const [mounted, setMounted] = useState(false);
	const [gender, setGender] = useState('male');
	const [showModalEmailSent, setShowModalEmailSent] = useState(false);
	const [info, setInfo] = useState([]);
	const [image, setImage] = useState(null);

	const initialValues = {
		first_name: '',
		age: '',
		password: '',
		password_confirm: '',
	};

	const changeGender = (genderOption) => {
		console.log(genderOption);
		setGender(genderOption);
	};

	const goToStep = (stepNumber) => {
		setStep(stepNumber);
	};

	const [saveInfoMutation, resultSaveInfo] = useMutation(UPDATE_INFO_USER, {
		onCompleted(data) {
			console.log(data);
			setInfo(data.updateUser);
			goToStep(2);
		},
		onError(error) {
			console.log(error);
		},
	});

	const [genderMutation, resultGender] = useMutation(UPDATE_GENDER, {
		onCompleted(data) {
			console.log(data);
			goToStep(3);
		},
		onError(error) {
			console.log(error);
		},
	});

	const [avatarMutation, resultAvatar] = useMutation(CREATE_AVATAR, {
		onCompleted(data) {
			console.log(data);
			setImage(sessionStorage.getItem('image_session'));
			sessionStorage.setItem('image_session', '');
			goToStep(4);
		},
		onError(error) {
			console.log(error);
		},
	});

	const saveGender = () => {
		genderMutation({
			variables: {
				gender_id: gender,
			},
		});
	};

	const createAvatar = () => {
		console.log('Crear Avatar');
		let imageSession;

		let viewSession = setInterval(() => {
			console.log('Buscando...');
			imageSession = sessionStorage.getItem('image_session');
			if (sessionStorage.getItem('image_session')) {
				avatarMutation({
					variables: {
						image: imageSession,
					},
				});
				clearInterval(viewSession);
			}
		}, 1000);
	};

	useEffect(() => {
		document.body.classList.add('bGreenLight');
		setStep(1);
		setMounted(true);
		console.log('process: ', process.env.REACT_APP_URL_IMAGES);

		/*const script = document.createElement('script');
        script.src = "/js/svgavatars.core.js";
        script.id = "core";
        document.body.appendChild(script);*/
		sessionStorage.setItem('image_session', '');
		localStorage.setItem('avatar_selected', false);

		let x = setInterval(() => {
			console.log(localStorage.getItem('avatar_selected'));
			if (
				localStorage.getItem('avatar_selected') == true ||
				localStorage.getItem('avatar_selected') == 'true'
			) {
				setStep(4);

				localStorage.setItem('avatar_selected', false);
			}
		}, 1000);

		return () => {
			clearInterval(x);
		};
	}, []);

	const Step1 = () => {
		return (
			<div className="setup-content " id="step-1">
				<div className="box_login_row">
					<div className="box_login_container">
						<div className="col-md-12">
							<div className="content_login">
								<div className="logo text-center">
									<img src={logoKulture} alt="Kulture4Kids" />
								</div>
								<h6 className="title_first_step text-center mt-3">
									¡Excelente! Ahora necesitamos saber más de tí.
								</h6>
								<h6 className="title_first_step text-center mt-3">
									Llena tu información y cambia tu clave secreta.
								</h6>

								<div className="row justify-content-center">
									<div className="col-12 col-md-9">
										<Formik
											initialValues={initialValues}
											validationSchema={Yup.object().shape({
												first_name: Yup.string().required('El nombre es obligatorio'),
												age: Yup.number()
													.typeError('Solo se permiten números.')
													.positive('Solo número positivo.')
													.integer('Solo se permiten enteros.')
													.required('La edad es obligatoria'),
												password: Yup.string()
													.required('La contraseña es requerida.')
													.min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
													.matches(
														/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
														'Debe de contener mayúscula, número y al menos un caracter especial.',
													),
												password_confirm: Yup.string()
													.required('Confirmar la contraseña es requerido.')
													.min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
													.oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.')
													.matches(
														/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
														'Debe de contener al menos una mayúscula, un número y un caracter especial.',
													),
											})}
											onSubmit={(values, { setSubmitting }) => {
												setTimeout(() => {
													setSubmitting(false);
													console.log({
														first_name: values.first_name,
														age: values.age,
														password: values.password,
													});
													saveInfoMutation({
														variables: {
															first_name: values.first_name,
															age: values.age,
															password: values.password,
														},
													});
												}, 500);
											}}
										>
											{({
												errors,
												handleBlur,
												handleChange,
												handleSubmit,
												isSubmitting,
												touched,
												values,
											}) => (
												<form
													onSubmit={handleSubmit}
													id="infoForm"
													name="infoForm"
													autoComplete="off"
													noValidate
												>
													<div className="form-row">
														<div className="col-md-6">
															<label htmlFor="nombre">¿Como te llamas?</label>
															<div className="icon_input mt-2 mb-2">
																<input
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.first_name}
																	type="text"
																	className="form-control"
																	id="id_nombre"
																	name="first_name"
																	placeholder="Nombre"
																	required
																/>
																{Boolean(touched.first_name && errors.first_name) && (
																	<div className="error">
																		{touched.first_name && errors.first_name}
																	</div>
																)}
															</div>
														</div>
														<div className="col-md-6">
															<label htmlFor="edad">¿Cuántos años tienes?</label>
															<div className="icon_input mt-2 mb-2">
																<input
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.age}
																	type="text"
																	className="form-control age"
																	id="age"
																	name="age"
																	maxLength="2"
																	placeholder="10"
																	required
																/>
																{Boolean(touched.age && errors.age) && (
																	<div className="error">{touched.age && errors.age}</div>
																)}
															</div>
														</div>
													</div>
													<div className="form-row">
														<div className="col-md-6">
															<label htmlFor="nueva_clave">Tu nueva clave secreta</label>
															<div className="icon_input mt-2 mb-2">
																<input
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.password}
																	type="password"
																	className="form-control password i-pass"
																	id="id_nueva_clave"
																	name="password"
																	placeholder="••••••••"
																	required
																/>
																<i className="toggle-password"></i>
																{Boolean(touched.password && errors.password) && (
																	<div className="error">{touched.password && errors.password}</div>
																)}
															</div>
														</div>
														<div className="col-md-6">
															<label htmlFor="confirmar_clave">Confirma tu clave secreta</label>
															<div className="icon_input mt-2 mb-2">
																<input
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.password_confirm}
																	type="password"
																	className="form-control password i-pass"
																	id="id_confirmar_clave"
																	name="password_confirm"
																	placeholder="••••••••"
																	required
																/>
																<i className="toggle-password"></i>
																{Boolean(touched.password_confirm && errors.password_confirm) && (
																	<div className="error">
																		{touched.password_confirm && errors.password_confirm}
																	</div>
																)}
															</div>
														</div>
														<div className="col-md-12 mt-4 mb-5 text-center setup-panel">
															<Button
																disabled={isSubmitting}
																title="Siguiente"
																color="primary"
																width="auto"
																type="submit"
															/>
														</div>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const Step2 = () => {
		return (
			<div className="setup-content" id="step-2">
				<div className="box_login_row">
					<div className="box_login_container">
						<div className="col-md-12">
							<div className="content_login">
								<div className="logo text-center">
									<img src={logoKulture} alt="Kulture4Kids" />
								</div>
								<h6 className="title_first_step text-center mt-3">
									¡Ahora vamos a diseñar tu avatar!
								</h6>
								<p className="text-gender text-center mt-3">Elige tu género</p>
								<div className="frm_login mt-5">
									<div className="form-row">
										<div className="content-avatar text-center">
											<div className="col-md-6">
												<img
													onClick={() => changeGender(1)}
													id="gender-man"
													className={
														'avatar-img gender-selection ' + (gender == 1 && 'avatarSelected')
													}
													src={avatar_men}
												/>
												<p className="text-gender">Masculino</p>
											</div>
											<div className="col-md-6">
												<img
													onClick={() => changeGender(2)}
													id="gender-woman"
													className={
														'avatar-img gender-selection ' + (gender == 2 && 'avatarSelected')
													}
													src={avatar_woman}
												/>
												<p className="text-gender">Femenino</p>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-4 mb-5 text-center">
										<Button
											title="Regresar"
											color="outline"
											width="auto"
											action={() => goToStep(1)}
											css="px-4 mr-sm-4"
										/>

										<Button
											title="Siguiente"
											color="primary"
											width="auto"
											action={() => saveGender()}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const Step3 = () => {
		return (
			<div className="setup-content " id="step-3">
				<div className="box_login_row">
					<div className="box_login_container">
						<div className="col-md-12">
							<div className="content_step">
								<div className="logo text-center mt-5">
									<img src={logoKulture} alt="Kulture4Kids" />
								</div>
								<h6 className="title_first_step text-center mt-3">
									¡Ahora vamos a diseñar tu avatar!
								</h6>
								<p className="text-gender text-center mt-3">Personaliza tu avatar</p>
								<div className="frm_login mt-4">
									<div className="container">
										{mounted && (
											<AvatarMaker
												goto={setStep}
												type="profile"
												gender={gender == 1 ? 'male' : 'female'}
												profile={true}
											/>
										)}
									</div>
									<div className="col-md-12 mt-4 mb-5 text-center">
										<Button
											title="Regresar"
											color="outline"
											width="auto"
											action={() => goToStep(2)}
											css="px-4 mr-sm-4"
										/>
										{/*}
										<Button
											id="createAvatarBtn"
											title="Siguiente"
											color="primary"
											width="auto"
											action={() => createAvatar()}
										/>
										{*/}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const Pagination = () => {
		return (
			<div className="stepwizard mt-4">
				<div className="stepwizard-row step-wizard">
					<div className="stepwizard-step step-done">
						<span
							id="step-link-1"
							data-page="1"
							className="btn btn-step-wizard-active content-bg-wizard step-link-1"
							style={{ cursor: 'inherit !important' }}
						>
							01
						</span>
					</div>
					<div
						className={
							'stepwizard-step setup-panel ' +
							(step == 2 || step == 3 || step == 4 ? 'step-done' : null)
						}
					>
						<span
							id="step-link-2"
							data-page="2"
							className="btn btn-step-wizard-inactive content-bg-wizard"
							disabled={true}
						>
							02
						</span>
					</div>
					<div className={'stepwizard-step ' + (step == 3 || step == 4 ? 'step-done' : null)}>
						<span
							id="step-link-3"
							data-page="3"
							className="btn btn-step-wizard-inactive content-bg-wizard"
							disabled={true}
							style={{ cursor: 'inherit !important' }}
						>
							03
						</span>
					</div>
					<div className={'stepwizard-step ' + (step == 4 ? 'step-done' : null)}>
						<span
							id="step-link-4"
							data-page="4"
							className="btn btn-step-wizard-inactive content-bg-wizard"
							disabled={true}
							style={{ cursor: 'inherit !important' }}
						>
							04
						</span>
					</div>
				</div>
			</div>
		);
	};

	return (
		<React.Fragment>
			<img className="bg_img_login" src={backgroundLogin} alt="" />

			<div className="container">
				<div id="FrmStudent" className="steps-profile needs-validation">
					<input type="hidden" name="avatar" value="" />
					<input type="hidden" id="gender" name="gender" value="M" />

					<input type="hidden" id="skincolor" name="skincolor" value="" />
					<input type="hidden" id="hairstyle" name="hairstyle" value="" />
					<input type="hidden" id="haircolor" name="haircolor" value="" />
					<input type="hidden" id="fabriccolors" name="fabriccolors" value="" />
					<input type="hidden" id="eyes" name="eyes" value="" />
					<input type="hidden" id="eyebrows" name="eyebrows" value="" />
					<input type="hidden" id="mouth" name="mouth" value="" />
					<input type="hidden" id="facialhair" name="facialhair" value="" />
					<input type="hidden" id="clothe" name="clothe" value="" />
					<input type="hidden" id="backgroundcolor" name="backgroundcolor" value="" />
					<input type="hidden" id="glasses" name="glasses" value="" />
					<input type="hidden" id="glassopacity" name="glassopacity" value="" />
					<input type="hidden" id="accesories" name="accesories" value="" />

					{step == 1 && <Step1 />}
					{step == 2 && <Step2 />}
					{step == 3 && <Step3 />}
					{step == 4 && <Step4 />}
				</div>

				<Pagination />

				<footer className="py-4 footer_cstm mt-5">
					<div className="container">
						<div className="row">
							<div className="col-md-12 text-center">
								<p>
									Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021 /{' '}
									<a>
										<strong>Políticas de privacidad</strong>
									</a>
								</p>
							</div>
						</div>
					</div>
				</footer>
			</div>

			<ModalEmailSent show={showModalEmailSent} setShow={setShowModalEmailSent} />
		</React.Fragment>
	);
}

export default CreateProfile;
