import { gql } from '@apollo/client';

export const ME = gql`
	query {
		me {
			id
			first_name
			last_name
			gender_id
			age
			mentor
			email
			roleId
			gender_id
		}
	}
`;

export const VALIDATE_RESET_PASSWORD = gql`
	query ($id: ID!, $code: String!) {
		validateResetPassword(id: $id, code: $code)
	}
`;

export const TOP10 = gql`
	query {
		listPoints {
			id
			first_name
			last_name
			total_points
			points
		}
	}
`;

export const MY_POINTS = gql`
	query {
		myPoints {
			points
			gems
		}
	}
`;

export const PUZZLES = gql`
	query {
		pending: pending_puzzles {
			id
			completed_games_id
			artworks_id
			userId
			image
			name
			thumbnail
			artists_id
			artist_first_name
			artist_last_name
			gems
			points
			available
			level_difficulty_id
			pieces
			shop
		}

		completed: puzzles_completed {
			id
			image
			name
			thumbnail
			artists_id
			artist_first_name
			artist_last_name
			gems
			available
			shop
		}

		bought: puzzles_bought {
			id
			image
			name
			thumbnail
			artists_id
			artist_first_name
			artist_last_name
			gems
			available
			shop
		}
	}
`;

export const PENDING_PUZZLES = gql`
	query ($limit: Int) {
		pending_puzzles(limit: $limit) {
			id
			completed_games_id
			artworks_id
			userId
			image
			name
			thumbnail
			artists_id
			artist_first_name
			artist_last_name
			gems
			points
			available
			level_difficulty_id
		}
	}
`;

export const GET_ARTWORK = gql`
	query ($id: ID!) {
		artwork: getArtwork(id: $id) {
			id
			completed_games_id
			artworks_id
			userId
			image
			name
			thumbnail
			artists_id
			artist_first_name
			artist_last_name
			gems
			points
			available
			level_difficulty_id
			pieces
			description
			audio
			artist_image
		}
	}
`;

export const GET_QUESTIONS = gql`
	query ($artboard_id: ID!) {
		question(artboard_id: $artboard_id) {
			id
			question
			answerType
			result
			answer {
				id
				value
				isCorrect
				selected
			}
		}
	}
`;

export const SHOP_PUZZLES = gql`
	query {
		shop_puzzles {
			id
			image
			name
			thumbnail
			artists_id
			artist_first_name
			artist_last_name
			gems
			available
		}
	}
`;

export const GET_AVATAR_INFO = gql`
	query {
		userAvatars {
			id
			userId
			image
			bodyZone
			colorCounter
			shapesCounter
			user_avatar_id
		}
	}
`;

export const MY_AVATARS = gql`
	query {
		myAvatars {
			id
			image
			active
			type
		}
	}
`;

export const GET_CUSTOM_AVATAR = gql`
	query {
		userCustomAvatars {
			id
			image
			active
			type
			gems
			title
		}
	}
`;

export const AVATARS = gql`
	query {
		avatars {
			id
			title
			gems
			priority
			available
			image
			createdAt
		}
	}
`;

export const LIST_AVATARS = gql`
	query {
		listAvatars {
			id
			title
			gems
			priority
			available
			image
		}
	}
`;

export const GET_AVATAR = gql`
	query {
		getAvatar {
			id
			image
		}
	}
`;

export const GET_AVATAR_USER = gql`
	query ($id: ID!) {
		getAvatarUser(id: $id) {
			id
			image
		}
	}
`;

export const GET_POINTS_AND_GEMS = gql`
	query {
		getPG {
			id
			userId
			points
			gems
			createdAt
		}
	}
`;

export const GET_AD = gql`
	query ($position: Int!) {
		ad: adPosition(position: $position) {
			id
			title
			start_date
			end_date
			image
			url
			mobile_image
			createdAt
			position
			weight
		}
	}
`;

export const GET_PG_LEVEL = gql`
	query ($pieces: Int!) {
		getPGLevel(pieces: $pieces) {
			id
			name
			description
			pieces
			points
			gems
			createdAt
			updatedAt
		}
	}
`;

export const GET_MY_SCHOOL_INFO = gql`
	query ($userId: ID!) {
		mySchoolInfo(userId: $userId) {
			id
			name
			grade
			teacher
			image
		}
	}
`;
