import React from 'react';

import ic_danger_info from '../../assets/img/icons/ic_danger_info.png';
import Button from '../Button';

function ModalNoGems({ show, setShow }) {
	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalNotGems"
				tabIndex="-1"
				aria-labelledby="modalNotGemsLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content radious-modal">
						<div className="text-center mt-4">
							<img className="wdt-2" src={ic_danger_info} />
						</div>
						<div className="modal-header notBrHeader mb-top15">
							<h5 className="titleModalHeader-2 text-title-modal-danger fontSize1">
								No cuentas con suficientes gemas para este canje.
							</h5>
						</div>
						<div className="modal-footer buttonFooterWLogin mb-3 pl-0 pr-0">
							<div className="row">
								<div className="col-12 col-md-6">
									<Button title="Cancelar" color="red" action={() => setShow(false)} />
								</div>
								<div className="col-12 col-md-6">
									<Button title="Comprar Gemas" color="purple" action={() => setShow(false)} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalNoGems;
