import ic_star from '../assets/img/icons/ic_star.png';
import ic_diamond from '../assets/img/icons/ic_diamond.png';
import { useLazyQuery } from '@apollo/client';
import { GET_POINTS_AND_GEMS } from '../services/queries';
import React, { useEffect, useState } from 'react';

function PointsGems({ text = true }) {
	const [points, setPoints] = useState(null);
	const [gems, setGems] = useState(null);

	const [getPG, resultPG] = useLazyQuery(GET_POINTS_AND_GEMS, {
		pollInterval: 2000,
		fetchPolicy: 'no-cache',
		/* onCompleted: (result) => {
			if (result) {
				console.log(result);
				if (result.getPG) {
					console.log('RESULTADO: GET_POINTS_AND_GEMS: ', result.getPG);
					setGems(result.getPG.gems);
					setPoints(result.getPG.points);
				}
			}
		}, */
	});

	useEffect(() => {
		if (resultPG.data && resultPG.data.getPG) {
			console.log('RESULTADO: GET_POINTS_AND_GEMS: ', resultPG.data.getPG);
			setGems(resultPG.data.getPG.gems);
			setPoints(resultPG.data.getPG.points);
		}
	}, [resultPG]);

	useEffect(() => {
		getPG();
	}, []);

	//if (!points) return false;

	return (
		<div className="container_my_poinst_progressbar_x2">
			<div className="content-star">
				<img width="40" height="40" className="star_icon" src={ic_star} />
				<span className="points_star_left">
					{points ? points : 0} {text == true && <span>puntos</span>}
				</span>
			</div>
			<div className="divider"></div>
			<div className="content-diamond">
				<img width="40" height="40" className="diamond_icon" src={ic_diamond} />
				<span className="points_diamond_left">
					{gems ? gems : 0} {text == true && <span>gemas</span>}
				</span>
			</div>
		</div>
	);
}

export default PointsGems;
