import ic_diamond from '../assets/img/icons/ic_diamond.png';

function DiamondPoints(props) {
	return (
		<div className="container_bagde_points_x2">
			<img src={ic_diamond} className="diamond_redeem_img" />
			<div className="points_redeem_badge">{props.points}</div>
		</div>
	);
}

export default DiamondPoints;
