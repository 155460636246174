import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';

import backgroundLogin from '../assets/img/background-login-student.png';
import logoKulture from '../assets/img/logo-kulture.png';
import ModalEmailSent from '../components/modals/ModalEmailSent';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { ACTIVE_ACCOUNT } from '../services/mutations';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import useNavigate from '../utils/useNavigate';

function ActiveUser() {
	const navigate = useNavigate();

	const search = useLocation().search;
	const code = new URLSearchParams(search).get('code');
	const id = new URLSearchParams(search).get('id');

	const [showModalEmailSent, setShowModalEmailSent] = useState(false);
	const [active, setActive] = useState(false);
	const [loading, setLoading] = useState(true);

	const [activeMutation, resultActive] = useMutation(ACTIVE_ACCOUNT, {
		onCompleted(data) {
			console.log(data);
			if (data.activeAccount == true) setActive(true);
			else setActive(false);
			setLoading(false);
		},
		onError(error) {
			console.log(error);
			setActive(false);
			setLoading(false);
		},
	});

	const active_account_now = () => {
		console.log(id, code);
		if (!id || !code) return false;
		activeMutation({
			variables: {
				code,
				id: parseInt(id),
			},
		});
	};

	const goToLogin = () => {
		navigate.goTo('/login');
	};

	useEffect(() => {
		setTimeout(() => {
			active_account_now();
		}, 2000);
		document.body.classList.add('bGreenLight');
	}, []);

	return (
		<React.Fragment>
			<main className="main_login">
				<img className="bg_img_login" src={backgroundLogin} alt="" />

				<div className="container">
					<div className="box_login_row">
						<div className="box_login_container row justify-content-center">
							<div className="col-md-10">
								<div className="content_login text-center">
									<div className="logo text-center">
										<img src={logoKulture} alt="" />
									</div>
									{loading ? (
										<h2 className={`title_h1 text-center mt-5 mb-5 cBlue`}>Espere un momento...</h2>
									) : (
										<>
											<h2 className={`title_h1 text-center mt-5 mb-5 ${active && 'cBlue'}`}>
												{active
													? `La cuenta ha sido activada!`
													: `¡Hubo un error al activar la cuenta!`}
											</h2>
											{active && (
												<Button
													action={() => goToLogin()}
													title="Iniciar Sesión"
													width="auto"
													css="mb-5"
												/>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>

					<div
						className="modal fade"
						id="modalSuccessEmail"
						tabIndex="-1"
						aria-labelledby="modalSuccessEmailLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog">
							<div className="modal-content radious-modal">
								<div className="modal-header notBrHeader"></div>
								<div className="modal-body">
									<p className="textMessage text-blue">
										Te hemos enviado un correo electrónico para confirmar tu cuenta e iniciar
										sesión.
									</p>
								</div>
								<div className="modal-footer buttonFooterWLogin">
									<a type="button" className="btn btn-primary buttonWLogin text-uppercase">
										Iniciar Sesión
									</a>
								</div>
							</div>
						</div>
					</div>

					<footer className="py-4 footer_cstm">
						<div className="container">
							<div className="row">
								<div className="col-md-12 text-center">
									<p>
										Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021 /{' '}
										<strong>Políticas de privacidad</strong>
									</p>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</main>

			<ModalEmailSent show={showModalEmailSent} setShow={setShowModalEmailSent} />
		</React.Fragment>
	);
}

export default ActiveUser;
