import Layout from './Layout';

import logoKulture from '../assets/img/logo-kulture.png';
import ic_arrow_right_white from '../assets/img/icons/ic_arrow_right_white.svg';
import manVector from '../assets/img/man.png';

import FooterAds from '../components/FooterAds';
import Button from '../components/Button';
import { useEffect, useState, useRef } from 'react';
import PuzzleBox from '../components/PuzzleBox';
import useAuth from '../auth/useAuth';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_AD, ME } from '../services/queries';
import { useHistory } from 'react-router-dom';

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { CREATE_AD_CLICK, CREATE_AD_IMPRESSION } from '../services/mutations';
import { useIntersection } from '../utils/UseIntersection';

function Home() {
	const [tourDone, setTourDone] = useState(false);
	const [man, setMan] = useState(false);
	const [me, setMe] = useState(false);
	const [ad1, setAd1] = useState([]);
	const [ad2, setAd2] = useState([]);
	const [ad4, setAd4] = useState([]);
	const history = useHistory();
	const [sessionTour, setSession] = useState();
	const user = useAuth();
	const [loginSwiper1, setLoginSwiper1] = useState(null);
	const [loginSwiper2, setLoginSwiper2] = useState(null);
	const [loginSwiper4, setLoginSwiper4] = useState(null);
	const refAd1 = useRef();
	const inViewportAd1 = useIntersection(refAd1, '0px');
	const refAd2 = useRef();
	const inViewportAd2 = useIntersection(refAd2, '0px');
	const refAd4 = useRef();
	const inViewportAd4 = useIntersection(refAd4, '0px');

	const [addClick, resultAddClick] = useMutation(CREATE_AD_CLICK, {
		onCompleted(data) {
			console.log(data);
			if (data.createAdClick) {
				console.log('CREATE_AD_CLICK: ', data);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const [addImpression, resultAddImpression] = useMutation(CREATE_AD_IMPRESSION, {
		onCompleted(data) {
			console.log(data);
			if (data.createAdImpressions) {
				console.log('CREATE_AD_IMPRESSION: ', data);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const do_prev = () => {
		loginSwiper1.slidePrev();
	};

	const do_next = () => {
		loginSwiper1.slideNext();
	};

	useEffect(() => {
		if (ad1.length > 0) {
			Swiper.use([Navigation, Pagination, Autoplay]);

			let tempSwiper = new Swiper('.ad1-swiper', {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 8000,
				},
				watchSlidesVisibility: true,
				on: {
					init: function (tempSwiper) {
						const index_currentSlide = tempSwiper.realIndex;
						const currentSlide = tempSwiper.slides[index_currentSlide];
						let dataID = currentSlide.getAttribute('data-id');

						if (inViewportAd1) {
							console.log('IMPRESSION AD 1 ADDED ON ----- INIT DATA.', {
								ads_id: parseInt(dataID),
							});
							addImpression({
								variables: {
									ads_id: parseInt(dataID),
								},
							});
						}
					},
				},
			});
			setLoginSwiper1(tempSwiper);

			tempSwiper.on('slideChange', function () {
				const index_currentSlide = tempSwiper.realIndex;
				const currentSlide = tempSwiper.slides[index_currentSlide];
				let dataID = currentSlide.getAttribute('data-id');
				//console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

				if (inViewportAd1) {
					console.log('IMPRESSION AD 1 ADDED.', {
						ads_id: parseInt(dataID),
					});
					addImpression({
						variables: {
							ads_id: parseInt(dataID),
						},
					});
				}
			});
		}
	}, [ad1]);

	useEffect(() => {
		if (ad2.length > 0) {
			Swiper.use([Navigation, Pagination, Autoplay]);

			let tempSwiper = new Swiper('.ad2-swiper', {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 8000,
				},
				on: {
					init: function (tempSwiper) {
						const index_currentSlide = tempSwiper.realIndex;
						const currentSlide = tempSwiper.slides[index_currentSlide];
						let dataID = currentSlide.getAttribute('data-id');

						if (inViewportAd2) {
							console.log('IMPRESSION AD 2 ADDED. --- ON INIT', {
								ads_id: parseInt(dataID),
							});
							addImpression({
								variables: {
									ads_id: parseInt(dataID),
								},
							});
						}
					},
				},
			});
			setLoginSwiper2(tempSwiper);

			tempSwiper.on('slideChange', function () {
				const index_currentSlide = tempSwiper.realIndex;
				const currentSlide = tempSwiper.slides[index_currentSlide];
				let dataID = currentSlide.getAttribute('data-id');
				//console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

				if (inViewportAd2) {
					console.log('IMPRESSION AD 2 ADDED.', {
						ads_id: parseInt(dataID),
					});
					addImpression({
						variables: {
							ads_id: parseInt(dataID),
						},
					});
				}
			});
		}
	}, [ad2]);

	useEffect(() => {
		if (ad4.length > 0) {
			Swiper.use([Navigation, Pagination, Autoplay]);

			let tempSwiper = new Swiper('.ad4-swiper', {
				loop: true,
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 8000,
				},
				watchSlidesVisibility: true,
				on: {
					init: function (tempSwiper) {
						const index_currentSlide = tempSwiper.realIndex;
						const currentSlide = tempSwiper.slides[index_currentSlide];
						let dataID = currentSlide.getAttribute('data-id');

						if (inViewportAd4) {
							console.log('IMPRESSION AD 4 ADDED ON ----- INIT DATA.', {
								ads_id: parseInt(dataID),
							});
							addImpression({
								variables: {
									ads_id: parseInt(dataID),
								},
							});
						}
					},
				},
			});
			setLoginSwiper4(tempSwiper);

			tempSwiper.on('slideChange', function () {
				const index_currentSlide = tempSwiper.realIndex;
				const currentSlide = tempSwiper.slides[index_currentSlide];
				let dataID = currentSlide.getAttribute('data-id');
				//console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

				if (inViewportAd4) {
					console.log('IMPRESSION AD 4 ADDED.', {
						ads_id: parseInt(dataID),
					});
					addImpression({
						variables: {
							ads_id: parseInt(dataID),
						},
					});
				}
			});
		}
	}, [ad4]);

	const [getAd1, resultAd1] = useLazyQuery(GET_AD, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.ad && result.ad.length > 0) {
					console.log('RESULTADO: GET_AD 1', result.ad);
					setAd1(result.ad);
				}
			}
		},
	});

	const [getAd2, resultAd2] = useLazyQuery(GET_AD, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.ad && result.ad.length > 0) {
					console.log('RESULTADO: GET_AD 2', result.ad);
					setAd2(result.ad);
				}
			}
		},
	});

	const [getAd4, resultAd4] = useLazyQuery(GET_AD, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.ad && result.ad.length > 0) {
					console.log('RESULTADO: GET_AD 4', result.ad);
					setAd4(result.ad);
				}
			}
		},
	});

	const [getMe, resultMe] = useLazyQuery(ME, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.me) {
					console.log('RESULTADO: getMe', result.me[0]);
					const meInfo = result.me[0];
					setMe(result.me[0]);
					setSession(localStorage.getItem('k4k_tour_home' + meInfo.id));
					if (meInfo) {
						if (meInfo.first_name && meInfo.age && meInfo.gender_id) {
							console.log('hay');
						} else history.push('/profile/create');
					}
				}
			}
		},
	});

	const verifyInfo = () => {
		console.log('verify .....');
		getMe();
	};

	useEffect(() => {
		if (me && me.id) {
			verifyFirstTime();
		}
	}, [me]);

	const verifyFirstTime = () => {
		let firstTime = localStorage.getItem('k4k_firsttime_our_home' + me.id);

		if (!firstTime) {
			localStorage.setItem('k4k_firsttime_our_home' + me.id, 0);
			initTour();
		}
	};

	const initTour = () => {
		//if (!sessionTour) {
		let tour;
		tour = introJs();
		tour.oncomplete(function () {
			setMan(false);
			setTourDone(true);
			console.log('k4k_tour_home' + me.id);
			localStorage.setItem('k4k_tour_home' + me.id, 1);
		});
		tour.setOptions({
			tooltipClass: 'customTour',
			showBullets: false,
			prevLabel: 'Anterior',
			nextLabel: 'Siguiente',
			doneLabel: 'Finalizado',
			exitOnEsc: false,
			exitOnOverlayClick: false,
			keyboardNavigation: true,
			steps: [
				{
					title: 'Mi perfil',
					intro: 'Aquí podrás editar tu información personal y personalizar tu avatar nuevamente.',
					element: document.querySelector('.step1'),
					position: 'right',
				},
				{
					title: 'Mis puntos',
					intro: 'Revisa tus puntos acumulados y la tabla de posiciones de otros jugadores.',
					element: document.querySelector('.step2'),
					position: 'right',
				},
				{
					title: 'Rompecabezas',
					intro: 'Arma tus rompecabezas asignados y realiza quizes mientras aprendes sobre arte.',
					element: document.querySelector('.step3'),
					position: 'right',
				},
				{
					title: 'Tienda',
					intro: 'Compra gemas, rompecabezas, avatares y productos exclusivos de Kulture 4 Kids.',
					element: document.querySelector('.step4'),
					position: 'right',
				},
				{
					title: 'Cerrar Sesión',
					intro: 'Aquí puedes cerrar tu sesión para que nadie más pueda ingresar a tu cuenta.',
					element: document.querySelector('.step5'),
					position: 'right',
				},
				{
					title: 'Sobre tu instituto',
					intro: 'Aquí puedes encontrar la información relevante a tu escuela y tu grado.',
					element: document.querySelector('.step6'),
					position: 'top',
				},
			],
		});
		tour.start();
		//setMan(true);
		//}
	};

	const getAllAds = () => {
		getAd1({
			variables: {
				position: 1,
			},
		});

		getAd2({
			variables: {
				position: 2,
			},
		});

		getAd4({
			variables: {
				position: 7,
			},
		});
	};

	useEffect(() => {
		verifyInfo();
		console.log(user.getUser());

		getAllAds();
		//setTourDone(false);
	}, []);

	useEffect(() => {
		console.log('sessionTour: ', sessionTour);
		if (sessionTour) {
			setTourDone(true);
		}
	}, [sessionTour]);

	const createAddClick = (id) => {
		console.log('createAddClick: ', id);
		addClick({
			variables: {
				ads_id: parseInt(id),
			},
		});
	};

	return (
		<Layout>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 bg_x2">
				<div className="containers container_main_view">
					<div className="row">
						<div className="col-5 col-md-6 col-lg-6">
							<div className="container-tutorial mt-2">
								<img className="wdt-1 img-fluid" src={logoKulture} alt="Kulture4Kids" />

								<div className="tour-begin">
									<h3 className="title-head-section gradient-title">
										Es momento de <br /> empezar a jugar y<br /> aprender.
									</h3>
									<div className="row mt-4 ml-1">
										{!tourDone && (
											<Button
												title="Ver tour Virtual"
												color="purple"
												width="auto"
												css="px-4"
												action={initTour}
											/>
										)}
									</div>
								</div>

								{tourDone && <PuzzleBox />}
							</div>
						</div>
						<div className="col-7 col-md-6 col-lg-6">
							<div className="row">
								<div className="col-6 col-md-6 col-lg-6 mb-2" ref={refAd1}>
									{ad1 && ad1.length > 0 ? (
										<div className="swiper ad1-swiper slider swiper-container">
											<div className="swiper-wrapper">
												{ad1.map((item, index) => (
													<div key={index} className="swiper-slide" data-id={item.id}>
														<a
															href={ad1.url}
															onClick={() => createAddClick(item.id)}
															target="blank"
															className="text-decoration-none"
														>
															<img src={item.image} alt="Ads" className="img-fluid" />
														</a>
													</div>
												))}
											</div>

											<div className="swiper-pagination"></div>
										</div>
									) : null}
								</div>

								<div className="col-6 col-md-6 col-lg-6 mb-2">
									{ad4 && ad4.length > 0 ? (
										<div className="swiper ad4-swiper slider swiper-container">
											<div className="swiper-wrapper">
												{ad4.map((item, index) => (
													<div key={index} className="swiper-slide" data-id={item.id}>
														<a
															href={ad4.url}
															onClick={() => createAddClick(item.id)}
															target="blank"
															className="text-decoration-none"
														>
															<img src={item.image} alt="Ads" className="img-fluid" />
														</a>
													</div>
												))}
											</div>

											<div className="swiper-pagination"></div>
										</div>
									) : null}
								</div>
							</div>

							<div className="row mt-3">
								<div className="col-12 col-md-12 col-lg-12" ref={refAd2}>
									{ad2 && ad2.length > 0 ? (
										<div className="swiper ad2-swiper slider swiper-container">
											<div className="swiper-wrapper">
												{ad2.map((item, index) => (
													<div key={index} className="swiper-slide" data-id={item.id}>
														<a
															href={ad2.url}
															onClick={() => createAddClick(item.id)}
															target="blank"
															className="text-decoration-none"
														>
															<img src={item.image} alt="Ads" className="img-fluid" />
														</a>
													</div>
												))}
											</div>

											<div className="swiper-pagination"></div>
										</div>
									) : null}
								</div>
							</div>

							<div className="row mt-3">
								<div className="col-12 text-right">
									{tourDone && (
										<Button
											title="Ver tour Virtual"
											color="purple"
											width="auto"
											css="px-4"
											action={initTour}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-4"></div>
			</div>

			<FooterAds />

			{man == true && <img src={manVector} alt="" className="man" />}
		</Layout>
	);
}

export default Home;
