import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../auth/useAuth';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import backgroundLogin from '../assets/img/background-login-student.png';
import logoKulture from '../assets/img/logo-kulture.png';
import imgSlide from '../assets/img/img-slide.jpg';
import imgSlide2 from '../assets/img/img-slide2.jpg';
import imgSlide3 from '../assets/img/img-slide3.jpg';
import React, { useEffect, useState } from 'react';
import ModalResetPassword from '../components/modals/ModalResetPassword';
import Button from '../components/Button';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { SIGNIN } from '../services/mutations';
import { useMutation } from '@apollo/client';

function Login() {
	const initialValues = {
		username: '',
		password: '',
	};

	const [showResetPassword, setShowResetPassword] = useState(false);
	const [errorMessage, seterrorMessage] = useState(null);

	const [signinMutation, resultDigninMutation] = useMutation(SIGNIN, {
		onCompleted(data) {
			console.log('data', data);
			if (data.signIn) {
				console.log(data.signIn.token);
				auth.login(data.signIn.token);
			}
		},
		onError(error) {
			console.log(error);
			error == 'Error: Invalid password.' && seterrorMessage('Contraseña inválida.');
			error == 'Error: No user found with this login credentials.' &&
				seterrorMessage('No se encontró el usuario.');
		},
	});

	useEffect(() => {
		//setShowResetPassword(true);
		document.body.classList.add('bGreenLight');
	}, []);

	const history = useHistory();
	const location = useLocation();
	const previusObjectURL = location.state?.from;

	const auth = useAuth();
	const handleLogin = () => {
		auth.login();
		history.push('/profile/create');
	};

	const goTo = (url) => {
		history.push(url);
	};

	const [loginSwiper, setLoginSwiper] = useState(null);

	const do_prev = () => {
		loginSwiper.slidePrev();
	};

	const do_next = () => {
		loginSwiper.slideNext();
	};

	useEffect(() => {
		Swiper.use([Navigation, Pagination]);

		let tempSwiper = new Swiper('.swiper', {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			centeredSlides: false,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		});

		setLoginSwiper(tempSwiper);
	}, []);

	return (
		<React.Fragment>
			<main className="main_login">
				<img className="bg_img_login" src={backgroundLogin} alt="" />

				<div className="container">
					<div className="box_login_row notZIndex">
						<div className="box_login_container">
							<div className="col-md-6 px-0">
								<div className="content_login">
									<div className="logo">
										<img src={logoKulture} alt="" />
									</div>
									<h2 className="title_h2 mt-3">
										Empieza a jugar y aprender ingresando tu usuario y tu clave secreta.
									</h2>
									<div className="frm_login">
										<Formik
											initialValues={initialValues}
											validationSchema={Yup.object().shape({
												username: Yup.string()
													.email('Debe ser un correo electrónico válido')
													.required('El correo electrónico es obligatorio'),
												password: Yup.string().required('La contraseña es requerida.'),
												/* .min(8, 'La contraseña es muy corta - Debería tener mínimo 8 caracteres.')
													.matches(
														/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
														'Debe de contener mayúscula, número y al menos un caracter especial.',
													), */
											})}
											onSubmit={(values, { setSubmitting }) => {
												setTimeout(() => {
													setSubmitting(false);
													console.log({
														login: values.username,
														password: values.password,
													});
													signinMutation({
														variables: {
															login: values.username,
															password: values.password,
														},
													});
												}, 500);
											}}
										>
											{({
												errors,
												handleBlur,
												handleChange,
												handleSubmit,
												isSubmitting,
												touched,
												values,
											}) => (
												<form
													onSubmit={handleSubmit}
													id="SignInForm"
													name="SignInForm"
													className="needs-validation siginInValidation"
													autoComplete="off"
													noValidate
												>
													<div className="form-group">
														<label htmlFor="username">Tu correo electrónico</label>
														<input
															onBlur={handleBlur}
															onChange={handleChange}
															type="email"
															className="form-control i-email"
															id="id_username"
															value={values.username}
															name="username"
															aria-describedby="emailHelp"
															placeholder=""
															required
														/>
														{Boolean(touched.username && errors.username) && (
															<div className="error">{touched.username && errors.username}</div>
														)}
													</div>
													<div className="form-group">
														<label htmlFor="password">Tu clave secreta</label>
														<div className="icon_input">
															<input
																onBlur={handleBlur}
																onChange={handleChange}
																type="password"
																className="form-control password i-pass"
																id="id_password"
																value={values.password}
																name="password"
																placeholder=""
																required
															/>
															<i className="toggle-password"></i>
															{Boolean(touched.password && errors.password) && (
																<div className="error">{touched.password && errors.password}</div>
															)}
														</div>
													</div>

													{errorMessage && (
														<div className="form-group container_error_message">
															<h6 className="title_h6 color-red mt-3">{errorMessage}</h6>
														</div>
													)}

													<Button
														type="submit"
														disabled={isSubmitting}
														title="Empezar"
														color="primary"
													/>

													<div className="remeberMe">
														<div className="row">
															<div className="col-12 col-md-12 col-lg-4 align-self-center">
																<div className="form-check">
																	<input
																		className="form-check-input"
																		type="checkbox"
																		id="inlineFormCheck"
																	/>
																	<label className="form-check-label" htmlFor="inlineFormCheck">
																		Recordarme
																	</label>
																</div>
															</div>
															<div className="col-12 col-md-12 col-lg-8 align-self-center">
																<a onClick={() => goTo('/resetpassword')}>
																	¿Olvidaste tu clave secreta?
																</a>
															</div>
														</div>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>

							<div className="col-md-6 px-0 ">
								<div className="swiper login-swiper slider swiper-container">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											<img src={imgSlide} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={imgSlide2} alt="" />
										</div>
										<div className="swiper-slide">
											<img src={imgSlide3} alt="" />
										</div>
									</div>

									<div className="swiper-pagination"></div>
									{/*}
                                <div onClick={do_prev} className="swiper-button-prev"></div>
                                <div onClick={do_next} className="swiper-button-next"></div>
                                <div className="swiper-scrollbar"></div>
                                {*/}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container pt-4 pb-2">
					<div className="col-12 text-center cBlue copyright">
						<p>
							Todos los derechos reservados, Kulture 4 Kids. Costa Rica. 2021 /{' '}
							<a>
								<strong>Políticas de privacidad</strong>
							</a>
						</p>
					</div>
				</div>
			</main>

			<ModalResetPassword show={showResetPassword} setShow={setShowResetPassword} />
		</React.Fragment>
	);
}

export default Login;
