import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { ADD_GAME, UPDATE_PG } from '../../services/mutations';
import { GET_QUESTIONS } from '../../services/queries';
import Button from '../Button';

function ModalQuiz({ show, setShow, quiz, id, info, level }) {
	const { points, gems, artworks_id, level_difficulty_id } = info;

	const [message, setMessage] = useState('');
	const [buttonStatus, setButtonStatus] = useState(true);
	const [disabledInput, setDisabledInput] = useState(false);

	const [pointsCount, setPointsCount] = useState(0);
	const [gemsCount, setGemsCount] = useState(0);
	const [answers, setAnswers] = useState([]);

	const [counter, setCounter] = useState(0);
	const incrementCounter = () => setCounter(counter + 1);
	const [options, setOptions] = useState(null);

	const [updatePG, resultUpdatePG] = useMutation(UPDATE_PG, {
		onCompleted(data) {
			console.log('updatePG: ', data.updatePG);
		},
		onError(error) {
			console.log(error);
		},
	});

	const [addMutation, resultAddMutation] = useMutation(ADD_GAME, {
		onCompleted(data) {
			console.log(data);
			setShow(false);
			quiz(true);
		},
		onError(error) {
			console.log(error);
		},
	});

	const [getQuestions, resultGetQuestions] = useLazyQuery(GET_QUESTIONS, {
		fetchPolicy: 'no-cache',
		/* onCompleted: (result) => {
			if (result) {
				console.log('RESULTADO: getQuestions: ', result.question);
				setOptions(result.question);
			}
		}, */
	});

	useEffect(() => {
		if (resultGetQuestions.data) {
			console.log(resultGetQuestions.data.question);
			setOptions(resultGetQuestions.data.question);
		}
	}, [resultGetQuestions]);

	useEffect(() => {
		console.log('id for questions: ', id);
		if (id) {
			getQuestions({
				variables: {
					artboard_id: parseInt(id),
				},
			});
		}
	}, [id]);

	useEffect(() => {
		console.log('Info: ', info);
	}, []);

	useEffect(() => {
		if (options) {
			if (options[counter]) setMessage(options[counter].question);
		}
	}, [options]);

	const addPoint = (points) => {
		updatePG({
			variables: {
				points: parseInt(points),
			},
		});
	};

	const addGems = (gems) => {
		updatePG({
			variables: {
				gems: parseInt(gems),
			},
		});
	};

	useEffect(() => {
		if (options && answers) {
			if (counter <= options.length - 1) {
				setDisabledInput(false);
				setMessage(options[counter].question);

				console.log('hacer cambio. . . ');
				if (counter > 0)
					document.querySelector('.question_options_' + (counter - 1)).classList.add('d-none');
				document.querySelector('.question_options_' + counter).classList.remove('d-none');
			} else {
				/* let winner = 0;
				options.map((item, index) => {
					if (parseInt(answers[index]) == parseInt(item.result)) {
						console.log('Todas OK!');
						winner++;
					} else {
						console.log('No Acertó en algunas!');
					}
				}); */

				let questions_completed = [];
				options.map((item, index) => {
					console.log('- - - - - - - - - - - - - - - - - ');
					if (parseInt(answers[index]) == parseInt(item.result)) {
						questions_completed.push(item.id);
					}
				});
				console.log('questions_completed', questions_completed);

				//if (options.length == winner) {

				const time = localStorage.getItem('k4k_puzzle_time');

				console.log({
					artworkId: id,
					levelId: level_difficulty_id ? level_difficulty_id : 0,
					points: pointsCount ? pointsCount : 0,
					gems: level.gems ? level.gems : 0,
					puzzle_time: time ? time : '',
					questions: questions_completed.length > 0 ? questions_completed.length : 0,
				});

				addMutation({
					variables: {
						artworkId: id,
						levelId: level_difficulty_id ? level_difficulty_id : 0,
						points: pointsCount ? pointsCount : 0,
						gems: level.gems ? level.gems : 0,
						puzzle_time: time ? time : '',
						questions: questions_completed.length > 0 ? questions_completed.length : 0,
					},
				});

				addPoint(level.points);

				console.log('gemsCount: ', gemsCount);
				console.log('level.gems: ', level.gems);
				const totalGems = parseInt(level.gems) + parseInt(gemsCount);
				console.log('totalGems: ', totalGems);
				addGems(totalGems);

				localStorage.setItem('k4k_gems_count_' + info.id, gemsCount);
				//}
			}
		}
	}, [counter, answers]);

	const changeOptions = () => {
		incrementCounter();
	};

	const fire = (particleRatio, opts) => {
		let defaults = {
			origin: { y: 0.7 },
		};
		let count = 200;

		confetti(
			Object.assign({}, defaults, opts, {
				particleCount: Math.floor(count * particleRatio),
			}),
		);
	};

	const doConfetti = () => {
		fire(0.25, {
			spread: 26,
			startVelocity: 55,
		});
		fire(0.2, {
			spread: 60,
		});
		fire(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});
		fire(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});
		fire(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	};

	useEffect(() => {
		if (answers) console.log(answers);
	}, [answers]);

	const handleRadio = (e, i, index) => {
		console.log(e, i);
		const { name, value } = e.target;

		const updatedValue = {};
		updatedValue[index] = i + 1;
		setAnswers((prevState) => ({
			...prevState,
			...updatedValue,
		}));

		setTimeout(() => {
			if (value == 'true') {
				doConfetti();
				setMessage('¡Correcto!');
				setGemsCount(gemsCount + 1);
			} else {
				setMessage('¡Incorrecto!');
			}

			//setCart({...options, answer[i]: {selected: true}});

			setDisabledInput(true);
			setButtonStatus(false);
		}, 500);
	};

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalQuestionsQuiz"
				tabIndex="-1"
				aria-labelledby="modalQuestionsQuizLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<form name="">
						<input type="hidden" name="" />
						<div className="modal-content radious-modal">
							<div className="modal-header notBrHeader p-0">
								<div className="bg_work_header">
									<img src={info.thumbnail} />
								</div>
								<h1 className="text_quiz_bigger">Quiz</h1>
							</div>
							<div className="modal-body">
								<div className="container_finish_completed_quiz">
									<h1 id="title_work_name" className="title_work_name text-danger-1 mt-4">
										{info.name}
									</h1>
									<h3 id="title_author_name" className="title_author_name">
										{`${info.artist_first_name} ${info.artist_last_name}`}
									</h3>
									<div className="line_middle_border_x1 mt-3 mb-3"></div>
									<h3 className="text_question_correct d-none">¡Correcto!</h3>
									<h3 className="text_question_wrong d-none">¡Incorrecto!</h3>

									<h3
										className={
											'title_question' + (message == '¡Incorrecto!' ? ' title_question_error' : '')
										}
									>
										{message}
									</h3>

									<div className="mt-2 mb-2"></div>
									<div className="container_congratulations">
										<div className="container_author_works text-center mt-3">
											{options &&
												options.map((element, index) => {
													return (
														<div
															key={index}
															className={
																'question_options question_options_' +
																index +
																' ' +
																(index > 0 && 'd-none')
															}
														>
															<div className="row">
																{element.answer.map((e, i) => {
																	return (
																		<div key={i} className="col-md-4 pointer text-center">
																			{element.answerType == 'image' ? (
																				<label className="radio-img">
																					<input
																						disabled={disabledInput == true ? 'disabled' : ''}
																						onChange={(e) => handleRadio(e, i, index)}
																						type="radio"
																						name={'option' + element.id}
																						value={e.isCorrect}
																					/>
																					<div className="image">
																						<img src={e.value} className="img-fluid" alt="" />
																					</div>
																				</label>
																			) : (
																				<label className="radio-img">
																					<input
																						disabled={disabledInput == true ? 'disabled' : ''}
																						onChange={(e) => handleRadio(e, i, index)}
																						type="radio"
																						name={'option' + element.id}
																						value={e.isCorrect}
																					/>
																					<div className="image replace_with_text">
																						<div className="text_option">{e.value}</div>
																					</div>
																				</label>
																			)}

																			<div className="mt-2 mb-2"></div>
																			{/*}<p className="quiz_content_answer name_author_quiz text_answer_success d-none">
																				Andy Warhol
																			</p>
																			{*/}
																		</div>
																	);
																})}
															</div>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer buttonFooterWLogin mt-3 mb-3 text-center">
								<Button
									title="Siguiente"
									color="purple"
									width="auto"
									disabled={options && options.length > 0 ? buttonStatus : false}
									action={changeOptions}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalQuiz;
