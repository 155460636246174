import React from 'react';
import { useEffect, useState } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';

import avatar_1 from '../assets/img/avatar_1.png';
import ic_danger_info from '../assets/img/icons/ic_danger_info.png';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Button from './Button';
import LoaderSpinner from './LoaderSpinner';
import { MY_AVATARS } from '../services/queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_AVATAR, USE_AVATAR } from '../services/mutations';

function AvatarSlider({ initMaker, isBuyed, getCustom }) {
	const [loginSwiper, setLoginSwiper] = useState(null);
	const [avatars, setAvatars] = useState([]);

	const [getAvatars, resultAvatars] = useLazyQuery(MY_AVATARS, {
		fetchPolicy: 'no-cache',
		pollInterval: 2000,
		/* onCompleted: (result) => {
			if (result) {
				console.log(result);
				if (result.myAvatars) {
					console.log('RESULTADO: MY_AVATARS: ', result.myAvatars);
					setAvatars(result.myAvatars);
				}
			}
		}, */
	});

	useEffect(() => {
		//console.log(resultAvatars.data);
		if (resultAvatars.data && resultAvatars.data.myAvatars) {
			//console.log('RESULTADO: MY_AVATARS: ', resultAvatars.data.myAvatars);
			setAvatars(resultAvatars.data.myAvatars);
		}
	}, [resultAvatars]);

	const [useAvatar, resultUseAvatar] = useMutation(USE_AVATAR, {
		onCompleted(data) {
			//console.log(data);
			if (data.useAvatar) {
				getAllAvatars();
				getCustom();

				let randomValue = Math.floor(Math.random() * 10);
				initMaker(randomValue);
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	const [deleteAvatar, resultDeleteAvatar] = useMutation(DELETE_AVATAR, {
		onCompleted(data) {
			//console.log(data);
			if (data.deleteUserAvatar) {
				getAllAvatars();
			}
		},
		onError(error) {
			console.log(error);
		},
	});

	useEffect(() => {
		if (avatars.length > 0) {
			setTimeout(() => {
				initSlider();
			}, 2000);
		}
	}, [avatars]);

	const do_prev = () => {
		loginSwiper.slidePrev();
	};

	const do_next = () => {
		loginSwiper.slideNext();
	};

	const initSlider = () => {
		Swiper.use([Navigation, Pagination]);

		let tempSwiper = new Swiper('.swiper', {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 25,
			centeredSlides: false,
			breakpoints: {
				640: {
					slidesPerView: 2,
					spaceBetween: 25,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 25,
				},
				1440: {
					slidesPerView: 4,
					spaceBetween: 25,
				},
			},
		});
		setLoginSwiper(tempSwiper);
	};

	const getAllAvatars = () => {
		getAvatars();
	};

	useEffect(() => {
		getAllAvatars();
	}, []);

	const changeAvatar = (id, type) => {
		console.log(id, type);

		if (type == 'buyed') isBuyed(true);
		else isBuyed(false);

		useAvatar({
			variables: {
				id,
				type,
			},
		});
	};

	const deleteAvatarNow = (id) => {
		deleteAvatar({
			variables: {
				id,
			},
		});
	};

	return (
		<React.Fragment>
			{avatars && avatars.length > 0 ? (
				<div className="container_bg_footer">
					<div className="row">
						<div className="col-12">
							<h3 className="title_head_my_avatars">Mis avatares</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-12 slider_container">
							<div className="swiper swiper-avatar-slider slider swiper-container">
								<div className="swiper-wrapper">
									{avatars.map((item, index) => (
										<div className="swiper-slide">
											{item.type == 'buyed' && <div className="buyed">COMPRADO</div>}
											<img
												src={
													item.type == 'buyed'
														? `${item.image}`
														: `${process.env.REACT_APP_URL_IMAGES}${item.image}`
												}
												className={`img-fluid avatar_redeem_item ${
													item.active == 1 ? 'avatar_redeem_item_active' : ''
												}`}
												height="80"
											/>
											{/*}
											<h6 className="my-1 title_avatar_x1 text-truncate mb-3 mt-2">
												Salvador Dalí
											</h6>
											{*/}
											<div className="buttons">
												<Button
													action={() => changeAvatar(item.id, item.type)}
													disabled={item.active == 1 ? true : false}
													title="Usar"
													color={'purple'}
													size="small"
												/>
												{item.active != 1 && (
													<Button
														action={() => deleteAvatarNow(item.id)}
														disabled={item.active == 1 ? true : false}
														title="X"
														color={'red'}
														size="small"
													/>
												)}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					{/*}
					<a onClick={do_prev} className="carousel-control-prev" data-slide="prev">
						<i className="fa fa-chevron-left"></i>
					</a>
					<a onClick={do_next} className="carousel-control-next" data-slide="next">
						<i className="fa fa-chevron-right"></i>
					</a>
					{*/}
				</div>
			) : (
				<div className="container_bg_footer">
					<div className="row mb-4">
						<div className="col-12">
							<h3 className="title_head_my_avatars">Mis avatares</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 align-self-center d-flex align-items-center">
							<img className="wdt-2 mr-3" src={ic_danger_info} />
							<p className="title_head_empty_avatars mb-0 vag">No tienes avatares canjeados.</p>
						</div>
						<div className="col-12 col-md-6 align-self-center">
							<Button
								title="Canjear Avatares"
								color="purple"
								//width="auto"
							/>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}

export default AvatarSlider;
