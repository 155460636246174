import React from 'react';

import intro_k4k from '../assets/img/intro_k4k.svg';
import ads_screen_1 from '../assets/img/ads/ads_screen_1.png';
import institute from '../assets/img/logo-institute-default.png';
import { useEffect, useState, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_AD, GET_MY_SCHOOL_INFO } from '../services/queries';

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { CREATE_AD_CLICK, CREATE_AD_IMPRESSION } from '../services/mutations';
import { useIntersection } from '../utils/UseIntersection';
import { isInteger } from 'formik';
import useAuth from '../auth/useAuth';

function FooterAds() {
	const [ad3, setAd3] = useState([]);
	const [loginSwiper, setLoginSwiper] = useState(null);
	const refAd3 = useRef();
	const inViewportFooter = useIntersection(refAd3, '-100px');
	//const [me, setMe] = useState([]);
	const [info, setInfo] = useState([]);

	const user = useAuth();
	const me = user.getUser();

	const do_prev = () => {
		loginSwiper.slidePrev();
	};

	const do_next = () => {
		loginSwiper.slideNext();
	};

	const [addClick, resultAddClick] = useMutation(CREATE_AD_CLICK, {
		onCompleted(data) {
			//console.log(data);
		},
		onError(error) {
			console.log(error);
		},
	});

	const [addImpression, resultAddImpression] = useMutation(CREATE_AD_IMPRESSION, {
		onCompleted(data) {
			//console.log(data);
		},
		onError(error) {
			console.log(error);
		},
	});

	const [getAd3, resultAd3] = useLazyQuery(GET_AD, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				if (result.ad && result.ad.length > 0) {
					//console.log('RESULTADO: GET_AD 3', result.ad);
					setAd3(result.ad);
				}
			}
		},
	});

	const [getInfo, resultGetInfo] = useLazyQuery(GET_MY_SCHOOL_INFO, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result && result.mySchoolInfo) {
				console.log('RESULTADO: GET_MY_SCHOOL_INFO', result.mySchoolInfo);
				setInfo(result.mySchoolInfo);
			}
		},
	});

	useEffect(() => {
		if (ad3.length > 0) {
			Swiper.use([Navigation, Pagination]);

			let tempSwiper = new Swiper('.ad3-swiper', {
				loop: false,
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 8000,
				},
			});
			setLoginSwiper(tempSwiper);

			tempSwiper.on('slideChange', function () {
				const index_currentSlide = tempSwiper.realIndex;
				const currentSlide = tempSwiper.slides[index_currentSlide];
				let dataID = currentSlide.getAttribute('data-id');
				//console.log('+ + + + + 3 slide changed data: ', dataID, inViewportFooter);

				if (isInteger(dataID)) {
					/* console.log('IMPRESSION AD 3 ADDED.', {
						ads_id: parseInt(dataID),
					}); */
					addImpression({
						variables: {
							ads_id: parseInt(dataID),
						},
					});
				}
			});
		}
	}, [ad3]);

	useEffect(() => {
		getAd3({
			variables: {
				position: 3,
			},
		});
	}, []);

	useEffect(() => {
		if (me && me.roleId === 3) {
			console.log('MEEEEEEE - - - - - - - - - -- - - - - -', me);
			getInfo({
				variables: {
					userId: me.id,
				},
			});
		}
	}, [me]);

	const createAddClick = (id) => {
		addClick({
			variables: {
				ads_id: parseInt(id),
			},
		});
	};

	return (
		<>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-1 mb-2 mt-2 p-0 d-none d-md-block">
				<div className="row mt-3">
					<div className="col-12 col-md-6 col-lg-7 col-xl-8 mt-2 mb-2">
						<div className="content_ads_footer" ref={refAd3}>
							{ad3 && ad3.length > 0 ? (
								<div className="swiper ad3-swiper slider swiper-container">
									<div className="swiper-wrapper">
										{ad3.map((item, index) => (
											<div key={index} className="swiper-slide" data-id={item.id}>
												<a
													href={ad3.url}
													onClick={() => createAddClick(item.id)}
													target="blank"
													className="text-decoration-none"
												>
													<img src={item.image} alt="Ads" className="img-fluid" />
												</a>
											</div>
										))}
									</div>

									<div className="swiper-pagination"></div>
								</div>
							) : null}
						</div>
					</div>

					<div className="col-12 col-md-6 col-lg-5 col-xl-4 mt-2 mb-2 text-right">
						{me && me.roleId == 3 && info && info.id && (
							<div
								className="container-institute-detail step6"
								style={{ bottom: 0, right: 0, margin: 0 }}
							>
								<div className="content-title-name-detail">
									<div className="row">
										<div className="col-md-9 pr-0 align-self-center">
											<h3>Sobre mi instituto</h3>
											<div className="content-subtitle-name-detail">
												<p>{info.name}</p>
											</div>
											<div className="content-subtitle-info-detail">
												<p>
													Profesor: <span>{info.teacher}</span>
												</p>
												<p>
													Grado: <span>{info.grade}</span>
												</p>
											</div>
										</div>
										<div className="col-md-3 align-self-center">
											<div className="content-logo-detai">
												<img className="logo-info-detail" src={info.image} alt={info.name} />
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div
					className="containers container_main_view p-0"
					style={{ position: 'relative', left: 0 }}
				>
					<div className="d-flex flex-sm-row flex-column p-0"></div>
					<div className="row"></div>
				</div>
			</div>

			<div id="cartoon-tour" className="text-center d-none">
				<div className="fixed-bottom fixed-bottom-cartoon">
					<img src={intro_k4k} alt="Start Tour" />
				</div>
			</div>
		</>
	);
}

export default FooterAds;
