import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_AVATAR, GET_AVATAR_USER } from '../../services/queries';

require('dotenv').config();

function AvatarUser({ id }) {
	const sizeDefault = 122;
	const [avatar, setAvatar] = useState(false);
	const [size, setSize] = useState(sizeDefault);

	const [getAvatar, resultGetAvatar] = useLazyQuery(GET_AVATAR, {
		fetchPolicy: 'no-cache',
		pollInterval: 3000,
	});

	const [getAvatarUser, resultGetAvatarUser] = useLazyQuery(GET_AVATAR_USER, {
		fetchPolicy: 'no-cache',
		pollInterval: 3000,
	});

	useEffect(() => {
		if (resultGetAvatar.data) {
			console.log('GET_AVATAR', resultGetAvatar.data.getAvatar);
			editAvatar(resultGetAvatar.data.getAvatar);
		}
	}, [resultGetAvatar]);

	useEffect(() => {
		if (resultGetAvatarUser.data) {
			console.log('GET_AVATAR_USER', resultGetAvatarUser.data.getAvatarUser);
			editAvatar(resultGetAvatarUser.data.getAvatarUser);
		}
	}, [resultGetAvatarUser]);

	const editAvatar = (ava) => {
		if (ava) {
			let temp = ava;
			console.log('ava.image ---->', ava.image);
			console.log('process ---->', ava.image.indexOf('http'));

			if (ava.image.indexOf('http') === -1)
				setAvatar(`${process.env.REACT_APP_URL_IMAGES}` + ava.image);
			else setAvatar(ava.image);
		}
	};

	useEffect(() => {
		if (id) {
			getAvatarUser({
				variables: {
					id,
				},
			});
			setSize(60);
		} else {
			getAvatar();
			setSize(sizeDefault);
		}
	}, []);

	return (
		<>
			{avatar ? (
				<img
					className="border-avatar"
					src={avatar}
					alt="avatar"
					width={size}
					height={size}
					//center={true}
				/>
			) : (
				<img
					className="border-avatar"
					width={size}
					height={size}
					src={`${process.env.PUBLIC_URL}/img/neutral_avatar.jpeg`}
				/>
			)}
		</>
	);
}

export default AvatarUser;
