import React, { useEffect, useState } from 'react';

import ic_star from '../../assets/img/icons/ic_star.png';
import ic_diamond from '../../assets/img/icons/ic_diamond.png';
import slide_puzzle_3 from '../../assets/img/slide_puzzle_3.png';
import Button from '../Button';

function ModalFinishPuzzle({ show, setShow, quiz, info, level }) {
	const continueWithQuiz = () => {
		quiz(true);
		setShow(false);
	};

	useEffect(() => {
		console.log('temp: ', info);
	}, [info]);

	return (
		<React.Fragment>
			<div
				className={'modal fade ' + (show ? 'show modal-block' : 'modal-none')}
				id="modalCompletedQuiz"
				tabIndex="-1"
				aria-labelledby="modalCompletedQuizLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg modal_completed_quiz pb-5">
					<div className="modal-content radious-modal">
						<div className="bg_container_work container_work">
							<div className="container_work_image">
								<img className="work_img img-br-1" src={info.thumbnail} />
							</div>

							<div className="container_text_congratulations">
								<div className="content_congratulations">
									<h5 className="title_have_congratulations">
										¡Felicidades! <br /> lo has completado
									</h5>

									<div className="row justify-content-between align-items-center">
										<div className="d-lg-flex d-none justify-content-between mx-auto">
											<div className="media align-items-center">
												<img className="wdt-2 mr-3" src={ic_star} />
												<div className="media-body pl-1">
													<h5 className="mb-0 text_points_gems text-light mr-3">
														{level.points ? level.points : 0} puntos
													</h5>
												</div>
											</div>
											<div className="media align-items-center">
												<img className="wdt-2 mr-3" src={ic_diamond} />
												<div className="media-body pl-1">
													<h5 className="mb-0 text_points_gems text-light">
														{level.gems ? level.gems : 0} gemas
													</h5>
												</div>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="container_audio">
											<div className="content_audio_track">
												<audio controls>
													<source src="" type="audio/mpeg" />
													Your browser does not support the audio element.
												</audio>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="modal-header notBrHeader p-0">
							<br />
						</div>
						<div className="modal-body">
							<div className="container_about_the_work_quiz">
								<h1 id="title_work_name" className="title_work_name">
									{info.name}
								</h1>
								<h3 id="title_author_name" className="title_author_name">
									{`${info.artist_first_name} ${info.artist_last_name}`}
								</h3>
								<div className="container_description_about_work">{info.description}</div>
							</div>
							<div className="divider"></div>
						</div>
						<div className="modal-footer buttonFooterWLogin mb-3 float-rights">
							<div className="row">
								<div className="col-12 col-md-8 align-self-center">
									<small className="form-text title-text-helper-quiz vag">
										Lea la información sobre la obra y complete el quiz para ganar{' '}
										<span className="text-points-extras text-purple">
											+{info.points ? info.points : 0} Puntos Extras.
										</span>
									</small>
								</div>
								<div className="col-12 col-md-4 text-right align-self-center">
									<Button title="Completar el Quiz" color="purple" action={continueWithQuiz} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={'modal-backdrop fade ' + (show ? 'show' : '')}></div>
		</React.Fragment>
	);
}

export default ModalFinishPuzzle;
