import React, { useState, useEffect, useRef } from 'react';
import Button from './Button';

const useAudio = (url) => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);

	const toggle = () => setPlaying(!playing);
	let audioPlayer = new Audio(url);

	useEffect(() => {
		//playing ? audioPlayer.play() : audioPlayer.pause();
		const playPromise = playing ? audioPlayer.play() : audioPlayer.pause();
		console.log('playing', playing);
		//const pausePromise = audioPlayer.pause();

		if (playPromise !== undefined) {
			playPromise
				.then((_) => {
					//audioPlayer.pause();
				})
				.catch((error) => {});
		}
	}, [playing]);

	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));

		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);

	return [playing, toggle];
};

const Player = ({ url }) => {
	//const [playing, toggle] = useAudio(url);
	const [audioStatus, changeAudioStatus] = useState(true);
	const myRef = useRef();

	const startAudio = () => {
		myRef.current.play();

		changeAudioStatus(false);
	};

	const pauseAudio = () => {
		myRef.current.pause();
		changeAudioStatus(true);
	};

	return (
		<div>
			{audioStatus ? (
				<Button action={() => startAudio()} title={'Escuchar'} color={'primary'} width="auto" />
			) : (
				<Button action={() => pauseAudio()} title={'Pausar'} color={'purple'} width="auto" />
			)}
			<audio ref={myRef} src={url} />
		</div>
	);
};

export default Player;
