import Layout from './Layout';
import { useEffect, useState } from 'react';

import logoKulture from '../assets/img/logo-kulture.png';
import check from '../assets/img/puzzle/check.png';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Swiper, { Navigation, Pagination } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import PointsGems from '../components/PointsGems';
import useNavigate from '../utils/useNavigate';
import useAuth from '../auth/useAuth';
import SkeletonList from '../components/skeleton/SkeletonList';
import LoaderSpinner from '../components/LoaderSpinner';
import { PUZZLES } from '../services/queries';
import { useLazyQuery } from '@apollo/client';

const SkeletonList2 = (props) => {
	return (
		<div className="skeleton skeleton-table">
			<SkeletonTheme>
				<table className="table">
					<tbody>
						<tr>
							<td width="200" className="">
								<Skeleton count={1} width="100%" height="400" />
							</td>
							<td width="200" className="">
								<Skeleton count={1} width="100%" height="400" />
							</td>
							<td width="200" className="">
								<Skeleton count={1} width="100%" height="400" />
							</td>
							<td width="200" className="">
								<Skeleton count={1} width="100%" height="400" />
							</td>
						</tr>
					</tbody>
				</table>
			</SkeletonTheme>
		</div>
	);
};

function Puzzle() {
	let tour;

	const navigate = useNavigate();
	const user = useAuth();

	const goTo = (url) => {
		navigate.goTo(url);
	};

	const [completeSlider, setCompleteSlider] = useState(null);
	const [incompleteSlider, setIncompleteSlider] = useState(null);
	const [shopSlider, setshopSlider] = useState(null);
	const [loadingShop, setLoadingShop] = useState(true);
	const [loadingIncomplete, setLoadingIncomplete] = useState(true);
	const [loadingComplete, setLoadingComplete] = useState(true);
	const [sessionTour, setSession] = useState();
	const [me, setMe] = useState();

	const [pending, setPending] = useState([]);
	const [completed, setCompleted] = useState([]);
	const [bought, setBought] = useState([]);

	const [puzzles, resultPuzzles] = useLazyQuery(PUZZLES, {
		fetchPolicy: 'no-cache',
		onCompleted: (result) => {
			if (result) {
				let mPending = result.pending;
				mPending.map((pending) => {
					pending.shop = 0;
				});

				let allPending = [...mPending, ...result.bought];
				let completed = result.completed;
				let deletePuzzle = [];

				if (result.pending) {
					console.log('allPending: ', allPending);
					completed.map((item, index) => {
						allPending = allPending.filter((e) => e.id !== item.id);
					});
					console.log('delete: ', allPending);
				}

				if (result.pending) {
					console.log('RESULTADO: Pending: ', result.completed);
					setPending(allPending);
				}

				if (result.completed) {
					console.log('RESULTADO: completed: ', result.completed);
					setCompleted(completed);
				}

				if (result.bought) {
					console.log('RESULTADO: bought: ', result.bought);
					setBought(result.bought);
				}
			}
		},
	});

	const do_prev_completed = () => {
		completeSlider.slidePrev();
	};

	const do_next_completed = () => {
		completeSlider.slideNext();
	};

	const do_prev_incompleted = () => {
		incompleteSlider.slidePrev();
	};

	const do_next_incompleted = () => {
		incompleteSlider.slideNext();
	};

	const do_prev_shop = () => {
		shopSlider.slidePrev();
	};

	const do_next_shop = () => {
		shopSlider.slideNext();
	};

	const initTour = () => {
		console.log('k4k_tour_puzzle_' + me.id);
		if (!localStorage.getItem('k4k_tour_puzzle_' + me.id)) {
			tour = introJs();
			tour.oncomplete(function () {
				localStorage.setItem('k4k_tour_puzzle_' + me.id, 1);
			});
			tour.setOptions({
				tooltipClass: 'customTour',
				showBullets: false,
				prevLabel: 'Anterior',
				nextLabel: 'Siguiente',
				doneLabel: 'Finalizado',
				exitOnEsc: true,
				exitOnOverlayClick: true,
				keyboardNavigation: true,
				steps: [
					{
						title: 'Pendientes',
						intro: 'Vivamus in erat ut urna.',
						element: document.querySelector('.slider-incomplete'),
						position: 'top',
					},
					{
						title: 'Completados',
						intro: 'Pellentesque habitant morbi tristique senectus.',
						element: document.querySelector('.slider-completed'),
						position: 'top',
					},
				],
			});
			tour.start();
		}
	};

	const goToPuzzle = (item) => {
		//me.roleId == 4 ? goTo('/levels/' + index) : goTo('/puzzlegame/' + index + '/' + pieces);
		const { id, pieces } = item;

		if (me.roleId == 4) {
			goTo('/levels/' + id);
		} else {
			item.shop == 1 ? goTo('/levels/' + id) : goTo('/puzzlegame/' + id + '/' + pieces);
		}
	};

	const getUser = () => {
		console.log('user.who');
		console.log(user.who);
		setMe(user.who);
	};

	useEffect(() => {
		getUser();
	}, [user.who]);

	useEffect(() => {
		//if (me) initTour();
	}, [me]);

	useEffect(() => {
		/* puzzles.map((item) => {
			const img = new Image();
			img.src = item.image;
		}); */

		setTimeout(() => {
			setLoadingIncomplete(false);
		}, 500);

		setTimeout(() => {
			setLoadingComplete(false);
		}, 1200);

		setTimeout(() => {
			setLoadingShop(false);
		}, 1600);

		puzzles();
	}, []);

	useEffect(() => {
		loadSliders();
	}, [loadingComplete, loadingIncomplete, loadingShop]);

	const loadSliders = () => {
		Swiper.use([Navigation, Pagination]);

		let tempCompleteSwiper = new Swiper('.swiper-complete-slider ', {
			loop: false,
			slidesPerView: 5,
			spaceBetween: 0,
			centeredSlides: false,
			breakpoints: {
				640: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 25,
				},
				1440: {
					slidesPerView: 5,
					spaceBetween: 45,
				},
			},
		});
		setCompleteSlider(tempCompleteSwiper);

		let tempIncompleteSwiper = new Swiper('.swiper-incomplete-slider ', {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 0,
			centeredSlides: false,
			breakpoints: {
				640: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 25,
				},
				1440: {
					slidesPerView: 4,
					spaceBetween: 25,
				},
			},
		});
		setIncompleteSlider(tempIncompleteSwiper);

		let tempShopSwiper = new Swiper('.swiper-shop-slider ', {
			loop: false,
			slidesPerView: 5,
			spaceBetween: 0,
			centeredSlides: false,
			breakpoints: {
				640: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 25,
				},
				1440: {
					slidesPerView: 5,
					spaceBetween: 45,
				},
			},
		});
		setshopSlider(tempShopSwiper);
	};

	if (!puzzles) return false;

	return (
		<Layout>
			<div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 bg_x2 h-100 puzzle_container">
				<div className="containers container_main_view">
					<div className="row align-items-center mt-1 mb-2 ml-3 mr-3">
						<div className="col-4 col-md-4 col-lg-4 align-self-center">
							<div className="row align-items-center">
								<div className="mr-5">
									<img className="logo_k4k_x1 img-fluid" src={logoKulture} alt="Kulture4Kids" />
								</div>
							</div>
						</div>
						<div className="col-8 col-md-8 col-lg-8 align-self-center d-flex justify-content-end">
							<PointsGems />
						</div>

						<div className="col-12">
							<h2>Mis rompecabezas</h2>

							{pending.length != 0 && (
								<div className="row justify-content-center">
									<div className="col-11 mb-5 slider-incomplete">
										<h3>Pendientes</h3>

										{loadingIncomplete == true ? (
											<LoaderSpinner height={250} />
										) : (
											<>
												<div className="swiper swiper-incomplete-slider slider swiper-container">
													<div className="swiper-wrapper">
														{pending.map((item, index) => {
															return (
																<div key={index} className="swiper-slide">
																	{item.shop === 1 && <div className="buyed">COMPRADO</div>}
																	<a onClick={() => goToPuzzle(item)}>
																		<img src={item.thumbnail} className="img-fluid" />
																		<h5>{item.name}</h5>
																		<p>
																			<strong>Pintor: </strong>
																			{`${item.artist_first_name} ${item.artist_last_name}`}
																		</p>
																	</a>
																</div>
															);
														})}
													</div>
												</div>

												<a
													onClick={do_prev_incompleted}
													className="carousel-control-prev"
													data-slide="prev"
												>
													<i className="fa fa-chevron-left"></i>
												</a>
												<a
													onClick={do_next_incompleted}
													className="carousel-control-next"
													data-slide="next"
												>
													<i className="fa fa-chevron-right"></i>
												</a>
											</>
										)}
									</div>
								</div>
							)}

							{completed.length != 0 && (
								<div className="row justify-content-center">
									<div className="col-11 slider-completed mt-5 mt-md-auto">
										<h3>Completados</h3>

										{loadingIncomplete == true ? (
											<LoaderSpinner height={250} />
										) : (
											<>
												<div className="swiper swiper-complete-slider slider swiper-container">
													<div className="swiper-wrapper">
														{completed.map((item, index) => {
															return (
																<div key={index} className="swiper-slide">
																	<div
																		className="container_completed"
																		onClick={() => goToPuzzle(item)}
																	>
																		<div
																			className="content_img"
																			style={{
																				background: 'url(' + item.image + ') no-repeat',
																				backgroundSize: 'cover',
																			}}
																		></div>
																		<div className="content_completed">
																			<img src={check} />
																			<h5>{item.name}</h5>
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
												<a
													onClick={do_prev_completed}
													className="carousel-control-prev"
													data-slide="prev"
												>
													<i className="fa fa-chevron-left"></i>
												</a>
												<a
													onClick={do_next_completed}
													className="carousel-control-next"
													data-slide="next"
												>
													<i className="fa fa-chevron-right"></i>
												</a>
											</>
										)}
									</div>
								</div>
							)}

							{bought.length != 0 && (
								<div className="row justify-content-center">
									<div className="col-11 mt-5 mb-5 slider-incomplete">
										<h3>Comprados en Tienda</h3>

										{loadingShop == true ? (
											<LoaderSpinner height={250} />
										) : (
											<>
												<div className="swiper swiper-shop-slider slider swiper-container">
													<div className="swiper-wrapper">
														{bought.map((item, index) => {
															return (
																<div key={index} className="swiper-slide">
																	<a onClick={() => goToPuzzle(item)}>
																		<img src={item.thumbnail} className="img-fluid" />
																		<h5>{item.name}</h5>
																		<p>
																			<strong>Pintor: </strong>
																			{`${item.artist_first_name} ${item.artist_last_name}`}
																		</p>
																	</a>
																</div>
															);
														})}
													</div>
												</div>

												<a
													onClick={do_prev_shop}
													className="carousel-control-prev"
													data-slide="prev"
												>
													<i className="fa fa-chevron-left"></i>
												</a>
												<a
													onClick={do_next_shop}
													className="carousel-control-next"
													data-slide="next"
												>
													<i className="fa fa-chevron-right"></i>
												</a>
											</>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Puzzle;
